import React, { Suspense } from 'react';

import { ThemeProvider } from '@emotion/react';
import { Provider } from '@nexus/lib-react/dist/core/Provider';
import { theme as LibReactTheme } from '@nexus/lib-react/dist/theme/CoreTheme';
import { ThemeProvider as LegacyThemeProvider } from 'emotion-theming';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';

import { Page } from '@olxui/core-v10/dist/core/Page/Page';

import FullPageLoader from './components/FullPageLoader';
import Header from './components/Header';
import { HelmetComponent } from './components/HelmetComponent';
import LoadableLoading from './components/LoadableLoading';
import { PortalNode } from './components/Portal/BottomOverlayPortal';
import ProgressLoader from './components/ProgressLoader';
import { getAppConfig } from './config';
import { AppProvider, ErrorHandlerProvider, LoaderProvider } from './contexts';
import { LanguageProvider } from './contexts/LanguageContext';
import { addLocaleData } from './helpers/lexemes';
import { useNewRelic } from './hooks/useNewRelic';
import { routes, RouteWithSubRoutes } from './routes/routes';
import {
  showFullPageLoader as showFullPageLoaderSelector,
  showProgressLoader,
} from './selectors/loader';
import { getTheme, GlobalStyle } from './styles';

type Props = {
  loading: boolean;
  isRTL: boolean;
  showProgress: boolean;
  lang: string;
  pageName: string;
  history: any;
  theme: any;
};

const App = ({
  history,
  isRTL,
  loading,
  lang,
  pageName,
  showProgress,
  theme,
}: Props) => {
  useNewRelic();

  return (
    <Provider theme={LibReactTheme}>
      <LegacyThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <ErrorHandlerProvider>
            <AppProvider>
              <LanguageProvider>
                <LoaderProvider>
                  <HelmetComponent lang={lang} isRTL={isRTL} />

                  <GlobalStyle />

                  <FullPageLoader isOpen={loading} />
                  <ProgressLoader isOpen={showProgress} />

                  <ConnectedRouter history={history as any}>
                    <Suspense fallback={<FullPageLoader isOpen={true} />}>
                      <Page
                        data-test={`${pageName}Page`}
                        backgroundColor="background-global-primary"
                      >
                        <Header loading={loading} />

                        <Page.Section>
                          <Switch>
                            {routes.map((route) => (
                              <RouteWithSubRoutes key={route.path} {...route} />
                            ))}
                          </Switch>
                        </Page.Section>
                        <PortalNode />
                      </Page>
                    </Suspense>
                  </ConnectedRouter>
                </LoaderProvider>
              </LanguageProvider>
            </AppProvider>
          </ErrorHandlerProvider>
        </ThemeProvider>
      </LegacyThemeProvider>
    </Provider>
  );
};

export const ConnectedApp = connect((state: any) => {
  const { pathname } = window.location;
  const theme = getTheme();

  return {
    loading: showFullPageLoaderSelector(state),
    pageName: getPageName(pathname),
    showProgress: showProgressLoader(state),
    theme,
  };
})(App as any) as any;

// eslint-disable-next-line react/display-name
export default ({ history }: any) => {
  const AppWithConfig = Loadable({
    loader: () =>
      getAppConfig().then((result) => {
        addLocaleData(result);
        return result;
      }),
    loading: LoadableLoading as any,
    render: (appConfig) => <ConnectedApp history={history} {...appConfig} />,
  });

  return <AppWithConfig />;
};

const getPageName = (pathname = '') => {
  pathname = pathname.split('/')[2];
  if (!pathname) {
    return '';
  }

  return pathname.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};
