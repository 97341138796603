import { combineActions, handleActions } from 'redux-actions';

import {
  ccStartAction,
  ccErrorAction,
  ccSuccessAction,
  ccRedirectAction,
  getRecurringPaymentAction,
} from '../actions/creditCard';
import {
  genActionAPIFailure,
  genActionAPIStart,
  genActionAPISuccess,
  genInitializeFailure,
  genInitializeStart,
  genInitializeSuccess,
  genInitByConvention,
  autoExecuteAction,
} from '../actions/generic';
import {
  ibtSelectBankAction,
  ibtSelectBankErrorAction,
  ibtOfflineBankSelectAttemptAction,
  ibtStartV2,
} from '../actions/instantBankTransfer';
import { loadableStart, loadableFinish } from '../actions/loadable';
import {
  pmStartAction,
  pmErrorAction,
  pmSuccessAction,
} from '../actions/paymentMethods';
import {
  psRequestStatusAction,
  psErrorAction,
  psTimeoutAction,
} from '../actions/paymentStatus';
import { initializeAction, finalizeAction } from '../actions/v2';

const defaultState = 0;

export const increment = (state) => state + 1;
export const decrement = (state) => Math.max(0, state - 1);

const showLoaderWhen = [
  // Actions that should show the loader.
  ccStartAction,
  ibtSelectBankAction,
  pmStartAction,
  psRequestStatusAction,
  loadableStart,
  getRecurringPaymentAction,
  // In the case of redirections, we want the spinner until the redirection happens
  ccRedirectAction,
  ibtStartV2,
  initializeAction,
  genInitializeStart,
  genActionAPIStart,
  genInitByConvention,
];

const hideLoaderWhen = [
  // Actions that should hide the loader.
  ccErrorAction,
  ccSuccessAction,
  ibtSelectBankErrorAction,
  ibtOfflineBankSelectAttemptAction,
  finalizeAction,
  pmErrorAction,
  pmSuccessAction,
  psTimeoutAction,
  psErrorAction,
  loadableFinish,
  genInitializeSuccess,
  genInitializeFailure,
  genActionAPISuccess,
  genActionAPIFailure,
  autoExecuteAction,
];

export default handleActions(
  {
    [combineActions(...showLoaderWhen) as any]: increment,
    [combineActions(...hideLoaderWhen) as any]: decrement,
  },
  defaultState,
);
