import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// defaults to localStorage for web and AsyncStorage for react-native
import allowPayment from './allowPayment';
import analytics from './analytics';
import creditCard from './creditCard';
import error from './error';
import fullPageLoader from './fullPageLoader';
import generic from './generic';
import global from './global';
import googlePay from './googlePay';
import instantBankTransfer from './instantBankTransfer';
import paymentMethods from './paymentMethods';
import paymentStatus from './paymentStatus';

const globalPersistConfig = {
  key: 'plutus-global',
  storage,
};

const analyticsPersistConfig = {
  key: 'plutus-analytics',
  storage,
};

const paymentStatusPersistConfig = {
  key: 'plutus-paymentStatus',
  storage,
  blacklist: [
    'paymentPendingVisible',
    'shouldRedirectToInterstitial',
    'statusRequestConfig',
  ],
};

export default combineReducers({
  global: persistReducer(globalPersistConfig, global as any),
  paymentStatus: persistReducer(
    paymentStatusPersistConfig,
    paymentStatus as any,
  ),
  analytics: persistReducer(analyticsPersistConfig, analytics as any),
  paymentMethods,
  creditCard,
  instantBankTransfer,
  error,
  router: routerReducer,
  form: formReducer,
  fullPageLoader,
  allowPayment,
  generic,
  googlePay,
});
