import ARROW_DOWN from '@olxui/core-v10/dist/core/Icon/icons/default/ArrowDown';
import ARROW_LEFT from '@olxui/core-v10/dist/core/Icon/icons/default/ArrowLeft';
import ARROW_RIGHT from '@olxui/core-v10/dist/core/Icon/icons/default/ArrowRight';
import CLOSE from '@olxui/core-v10/dist/core/Icon/icons/default/Close';
import INFO from '@olxui/core-v10/dist/core/Icon/icons/default/Info';
import CVV_CARD from '@olxui/core-v10/dist/core/Icon/icons/illustrations/CreditCard';
import LOGO from '@olxui/core-v10/dist/core/Icon/icons/illustrations/Logo';
import SHOCKED from '@olxui/core-v10/dist/core/Icon/icons/illustrations/Shocked';

import AUTOVIT_LOGO from '../../svg/autovit-logo.svg';
import CVV from '../../svg/cvv.svg';
import IMOVIRTUAL_LOGO from '../../svg/imovirtual-logo.svg';
import MOBILE_WALLET from '../../svg/mobile-wallet.svg';
import OTODOM_LOGO from '../../svg/otodom-logo.svg';
import OTOMOTO_LOGO from '../../svg/otomoto-logo.svg';
import PENDING from '../../svg/pending.svg';
import INSTANT_BANK_TRANSFER from '../../svg/pm-bank-transfer-instant.svg';
import SMS from '../../svg/sms.svg';
import STANDVIRTUAL_LOGO from '../../svg/standvirtual-logo.svg';
import STORIA_LOGO from '../../svg/storia-logo.svg';
import MBWAY_WAITING from '../../svg/stv-waiting-mbway.svg';
import MULTIBANCO_WAITING from '../../svg/stv-waiting-multibanco.svg';

const staticPath = '/images';

const AMEX = `${staticPath}/americanexpress.svg`;
const GENERIC_CARD = `${staticPath}/cc-generic.svg`;
const RUPAY = `${staticPath}/cc-rupay.png`;
const TROY = `${staticPath}/cc-troy.png`;
const DINERS_CLUB = `${staticPath}/ccicon-dinersclub.svg`;
const DISCOVER = `${staticPath}/ccicon-discover.svg`;
const MAESTRO = `${staticPath}/ccicon-maestro.svg`;
const UNIONPAY = `${staticPath}/ccicon-unionpay.svg`;
const MAGNIFYING_GLASS = `${staticPath}/grey-search.png`;
const JCB = `${staticPath}/jcb.svg`;
const MAGNIFYING = `${staticPath}/magnifying-glass.svg`;
const MASTERCARD_SECURECODE = `${staticPath}/mastercard-securecode.svg`;
const MASTERCARD = `${staticPath}/mastercard.svg`;
const PADLOCK = `${staticPath}/padlock.svg`;
const SUCCESS = `${staticPath}/success.svg`;
const VERIFIED_VISA = `${staticPath}/verified-visa.svg`;
const VISA = `${staticPath}/visa.svg`;
const WRONG = `${staticPath}/wrong.svg`;
const PROSTIR = `${staticPath}/prostir.svg`;

export const commonIcons = {
  AMERICAN_EXPRESS: AMEX,
  AMEX,
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  CLOSE,
  CVV,
  CVV_CARD,
  DINERS_CLUB,
  DISCOVER,
  DISCOVERY: DISCOVER,
  GENERIC: GENERIC_CARD,
  INFO,
  INSTANT_BANK_TRANSFER,
  JCB,
  LOGO,
  MAESTRO,
  MAGNIFYING,
  MAGNIFYING_GLASS,
  MASTERCARD: MASTERCARD,
  MASTERCARD_SECURECODE,
  MOBILE_WALLET,
  MOCK: GENERIC_CARD,
  OLD_MAESTRO: MAESTRO,
  PADLOCK,
  PROSTIR,
  RUPAY,
  SHOCKED,
  SMS,
  SUCCESS,
  TROY,
  UNIONPAY,
  VERIFIED_VISA,
  VISA: VISA,
  WRONG,
  PENDING,
};

export const apps = {
  pl: commonIcons,
  ro: commonIcons,
  ua: commonIcons,
  otodom_pl: {
    ...commonIcons,
    LOGO: OTODOM_LOGO,
  },
  storia_ro: {
    ...commonIcons,
    LOGO: STORIA_LOGO,
  },
  otomoto_pl: {
    ...commonIcons,
    LOGO: OTOMOTO_LOGO,
  },
  autovit_ro: {
    ...commonIcons,
    LOGO: AUTOVIT_LOGO,
  },
  imovirtual_pt: {
    ...commonIcons,
    LOGO: IMOVIRTUAL_LOGO,
  },
  standvirtual_pt: {
    ...commonIcons,
    LOGO: STANDVIRTUAL_LOGO,
    MULTIBANCO_WAITING,
    MBWAY_WAITING,
  },
};
