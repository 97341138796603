import cardValidator from 'card-validator';
import { flow, get, getOr, max, pick, replace, isEmpty, trim } from 'lodash/fp';
import { createSelector, createStructuredSelector } from 'reselect';

import getAppId from '../helpers/getAppId';
import { psp as qpPSP } from '../selectors/generic';
import { locale, paymentId } from './global';
import { paymentMethods } from './paymentMethods';
import { selectedMethod } from './paymentStatus';

const creditCard = createSelector(get('creditCard'), (x) => x);

const paymentStatus = get('paymentStatus');
const paymentLoading = createSelector(paymentStatus, get('loading'));

export const paymentInfo = getOr({}, 'creditCard.paymentInfo');

export const psp = createSelector(
  [selectedMethod, paymentInfo, qpPSP], // This selector returns psp value from query param, and it should take precedence
  (cm = {}, pi: any = {}, qp) => {
    return qp || cm.psp || pi.psp;
  },
);

export const formValues = flow(
  getOr({}, 'form.creditCard.values'),
  // This is requires dues to the difference in the masking libraries. All the PSP encoders are expecting expiry dd/dd and we have dd / dd
  (formValues: any) =>
    formValues.ccexpiry
      ? {
          ...formValues,
          ccexpiry: formValues.ccexpiry.replace(' / ', '/'),
        }
      : formValues,
);

const drawMask = (length, gaps) => {
  if (!gaps || !length) {
    return '#### #### #### ####';
  }
  const mask = new Array(length).fill('#', 0, length);
  gaps.forEach((gap, i) => {
    mask[gap + i] = ' ';
  });
  return mask.join('');
};

export const trimmedCardNumber = createSelector(
  formValues,
  flow(get('ccnumber'), replace(/\D/g, ' '), trim),
);

const validatedCardNumber: any = createSelector(trimmedCardNumber, (number) =>
  cardValidator.number(number),
);

const cardNumberLength = createSelector(
  validatedCardNumber,
  flow(
    get('card'),
    pick(['lengths', 'gaps']),
    ({ lengths = [], gaps = [] }: any) => max([0, ...lengths]) + gaps.length,
  ),
);

const cardNumberMask = createSelector(
  validatedCardNumber,
  cardNumberLength,
  ({ card }, length) => {
    if (!card) {
      return drawMask(false, false);
    }
    const { gaps } = card;

    return drawMask(length, gaps);
  },
);

export const cvvMeta = createSelector(
  validatedCardNumber,
  getOr(
    {
      name: 'CVV',
      size: 3,
    },
    'card.code',
  ),
);

export const cardType = createSelector(
  validatedCardNumber,
  getOr('generic', 'card.type'),
);

export const isCvvRequired = createSelector(
  cardType,
  (type) => type !== 'maestro',
);

const cardValid = createSelector(
  validatedCardNumber,
  get('isPotentiallyValid'),
);

const isPaymentInfoReady = createSelector(
  paymentInfo,
  (paymentInfoResult) => !isEmpty(paymentInfoResult),
);

const shouldShowStoreDetails = createSelector(
  paymentInfo,
  ({ context }: Record<string, any> = {}) => {
    if (context) {
      return context.allowTokenization;
    }
    // Temporary verification for V1:
    return getAppId() !== 'in';
    // return true;
  },
);
const shouldShowNameField = createSelector(
  paymentInfo,
  ({ context }: Record<string, any> = {}) => {
    if (context) {
      return context.allowNameInput;
    }
    return true;
  },
);
const shouldTokenizeByDefault = createSelector(
  paymentInfo,
  ({ context }: Record<string, any> = {}) => {
    if (context) {
      return context.tokenizeByDefault;
    }
    return false;
  },
);
const shouldShowTokenizeInfo = createSelector(
  paymentInfo,
  ({ context }: Record<string, any> = {}) => {
    if (context) {
      return context.showTokenizationInfo;
    }
    return true;
  },
);
export const paymentDetails = createSelector(
  createSelector(paymentMethods, get('payment')),
  createSelector(paymentInfo, get('context')),
  (pi, ci) => pi || ci,
);

export const creditCardSelector = createStructuredSelector({
  creditCard,
  details: paymentDetails,
  paymentMethods,
  status: paymentStatus,
  cvvMeta,
  isCvvRequired,
  locale,
  paymentId,
  isPaymentInfoReady,
  psp,
  paymentLoading,
  validatedCardNumber,
  cardNumberMask,
  cardType,
  cardValid,
  cardNumberLength,
  shouldShowStoreDetails,
  shouldShowNameField,
  shouldTokenizeByDefault,
  shouldShowTokenizeInfo,
} as Record<string, any>);
