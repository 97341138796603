const eventsNameListForIsOneOfEvents = [
  'aboutolx_link_selected',
  'aboutolx_page_viewed',
  'aboutolx_back_button',
  'method_link_selected',
  'payment_selection_link_selected',
];
export const isOneOfEvents = (
  _properties: Record<string, any>,
  eventName = '',
) =>
  eventsNameListForIsOneOfEvents.indexOf(eventName.toLowerCase().trim()) > -1;

export const propertiesScrubConfig = {
  method_type: isOneOfEvents,
};
