import { useContext } from 'react';

import { useIntl } from 'react-intl';

import { AppContext } from '../../contexts';

export const useIntegratorTranslation = (key: string): string => {
  const { formatMessage } = useIntl();

  const {
    parsedParams: { init },
  } = useContext(AppContext);

  const intergratorInfo = init?.integrator?.toLowerCase()?.split('_');

  const intergratorName = intergratorInfo?.length
    ? intergratorInfo[0].toLocaleLowerCase()
    : '';

  const keyContent = formatMessage({ id: `${key}.${intergratorName}` });
  if (keyContent !== undefined && keyContent !== `${key}.${intergratorName}`) {
    return `${key}.${intergratorName}`;
  }

  return key;
};
