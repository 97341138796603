import { autovit } from './autovit';
import { imovirtual } from './imovirtual';
import { olxClassic } from './olxClassic';
import { otodom } from './otodom';
import { otomoto } from './otomoto';
import { standvirtual } from './standvirtual';
import { storia } from './storia';

export const availableThemes = {
  olxClassic,
  otodom,
  storia,
  otomoto,
  autovit,
  imovirtual,
  standvirtual,
};

export const themeSelector = {
  otodom_pl: availableThemes.otodom,
  storia_ro: availableThemes.storia,
  otomoto_pl: availableThemes.otomoto,
  autovit_ro: availableThemes.autovit,
  imovirtual_pt: availableThemes.imovirtual,
  standvirtual_pt: availableThemes.standvirtual,
  default: availableThemes.olxClassic,
};
