import {
  curryN,
  get,
  find,
  flip,
  flow,
  getOr,
  map,
  filter,
  lowerCase,
  includes,
} from 'lodash/fp';
import { createSelector, createStructuredSelector } from 'reselect';

import * as bankConstants from '../bankConstants';

const main = get('instantBankTransfer');

const getOpeningHours = (bank) => {
  const result = bankConstants.openingHours[bank.issuerId];

  if (!result) {
    return result;
  }

  const hour = {
    from: result.hour.from,
    to: result.hour.to,
  };

  return {
    ...result,
    hour,
  };
};

export const searchFilter = createSelector(main, get('searchFilter'));

const banks = createSelector(main, (state) =>
  flow(
    getOr([], 'banks'),
    filter(
      flow(
        getOr('', 'name'),
        lowerCase,
        includes(state.searchFilter.toLowerCase()),
      ),
    ),
    map((bank: Record<string, any>) => ({
      ...bank,
      openingHours: getOpeningHours(bank),
    })),
  )(state),
);

const parameterizeSelector = (state, props) => props;

const takePropsBeforeState = flow(flip, curryN(2));

const findByIssuerId = (banksList, issuerId) =>
  find((x) => x.issuerId === issuerId, banksList);

const bankById = takePropsBeforeState(
  createSelector(banks, parameterizeSelector, findByIssuerId),
);

const offlineSelectedNoticeVisible = createSelector(
  main,
  get('offlineSelectedNoticeVisible'),
);

export { bankById };

export default createStructuredSelector({
  banks,
  offlineSelectedNoticeVisible,
  searchFilter,
});
