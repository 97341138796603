import { useContext, useEffect } from 'react';

import useAxios from 'axios-hooks';

import { LoaderContext, useErrorHandler } from '../../contexts';
import { redirect } from '../../helpers';
import { TExecuteCommonApiResponse } from './types';

export const handleGenericResponseTypes = (data: TExecuteCommonApiResponse) => {
  try {
    //? Is this still needed?
    const action =
      data?.actions && data?.actions?.submit
        ? data.actions.submit
        : data?.action;

    switch (action?.type) {
      case 'REDIRECT':
        redirect({ payload: { url: data?.actions?.submit?.uri } });
        break;
      default:
        break;
    }
  } catch (error) {
    new Error(JSON.stringify(error));
  }
};

export const usePaymentExecuteRequest = <Request = any, Response = any>(
  url: string,
) => {
  const { handleApiErrorResponse } = useErrorHandler();

  const [{ data, loading, error }, initRequest] = useAxios<
    TExecuteCommonApiResponse & Response,
    Request
  >(
    {
      url,
      method: 'post',
    },
    { manual: true },
  );

  const { showLoading, hideLoading } = useContext(LoaderContext);

  const handleExecuteRequest = async (props?: Request) => {
    try {
      await initRequest({ data: props });
    } catch (responseError) {
      handleApiErrorResponse(responseError);
    }
  };

  useEffect(() => {
    loading ? showLoading() : hideLoading();
  }, [loading]);

  return { data, error, handleExecuteRequest };
};
