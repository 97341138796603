import { fork, take } from 'redux-saga/effects';

export const combineLatest = (actionTypes, saga, ...args) =>
  fork(function* () {
    const actions = {};
    while (true) {
      const action = yield take(actionTypes);
      actions[action.type] = action;

      if (allActionsReady(actions, actionTypes)) {
        yield saga(
          ...args,
          actionTypes.map((t) => actions[t]),
        );
      }
    }
  });

function allActionsReady(actions, actionTypes) {
  return Object.keys(actions).length === actionTypes.length;
}
