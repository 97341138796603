import React, { FC, useState, createContext, useContext } from 'react';

import { ProgressLoader } from '../../components';
import FullPageLoader from '../../components/FullPageLoader';

type TypeComponentOptions = 'full' | 'progress';

type LoaderContextProviderProps = {
  isLoading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
  handleTypeChange: (type: TypeComponentOptions) => void;
};

export const LoaderContext = createContext<LoaderContextProviderProps>(
  {} as LoaderContextProviderProps,
);

export const useLoaderContext = () => useContext(LoaderContext);

export const LoaderProvider: FC = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState<TypeComponentOptions>('full');

  const showLoading = () => setLoading(() => true);
  const hideLoading = () => setLoading(() => false);
  const handleTypeChange = (type: TypeComponentOptions) => setLoadingType(type);

  return (
    <LoaderContext.Provider
      value={{ isLoading, showLoading, hideLoading, handleTypeChange }}
    >
      {<FullPageLoader isOpen={loadingType === 'full' && isLoading} />}
      {<ProgressLoader isOpen={loadingType === 'progress' && isLoading} />}
      {children}
    </LoaderContext.Provider>
  );
};
