import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

import { codesToTranslationKey } from '../helpers/codesToTranslation';
import type { PlutusMessage } from '../types/PlutusMessages';

const main = get('error');

const rawGlobalError = createSelector(main, get('global'));
const rawCardError = createSelector(main, get('card'));

const RETRY_PAYMENT_ERROR = 'app.common.error.retry_payment';
const CHOOSE_ANOTHER_METHOD_ERROR = 'app.common.error.choose_another_method';

//TODO: to be removed after migration to new error codes
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const errorResourceIds: {
  [key in PlutusMessage]: string;
} = {
  AUTH_ERROR: 'app.common.error.something_wrong_choose_another_method',
  TIMED_OUT: RETRY_PAYMENT_ERROR,
  CANCELLED: RETRY_PAYMENT_ERROR,
  SYNTAX_ERROR: 'app.common.error.something_wrong_choose_another_method',
  SERVER_ERROR: RETRY_PAYMENT_ERROR,
  INSUFFICIENT_FUNDS: CHOOSE_ANOTHER_METHOD_ERROR,
  NOT_SUPPORTED: CHOOSE_ANOTHER_METHOD_ERROR,
  PIN_ERROR: 'app.common.error.choose_another_method_or_phone_number',
  CHARGE_ERROR: 'app.common.error.retry_sms',
  CHARGE_LIMIT: 'app.common.error.choose_another_method_or_phone_number',
  RETRY_METHOD: 'app.common.error.retry_method',
  TRY_OTHER_METHOD: CHOOSE_ANOTHER_METHOD_ERROR,
  RE_ENTER_CARD_DATA: 'app.common.error.re_enter_card_data',
  PENDING: 'app.common.error.pending',
  UNKNOWN: 'app.common.error.generic',
  ...codesToTranslationKey,
};

const getErrorResourceId = (err) =>
  err ? errorResourceIds[err] || `app.common.error.${err}` : '';

export const error = createSelector(rawGlobalError, getErrorResourceId);

export const cardError = createSelector(rawCardError, (err) => {
  return getErrorResourceId(err);
});

export const hasError = createSelector(error, (err) => !!err);
