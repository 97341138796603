import { decode } from 'js-base64';
import qs from 'query-string';

type InitParams = {
  result?: { locale: string };
  vars?: { init?: string };
};

const parseInitParams = (
  queryString: string = window.location.search,
): InitParams => {
  const vars = qs.parse(queryString);
  if (!(vars && vars.init)) {
    return { vars };
  }
  const json = decode(vars.init as string);
  const result = JSON.parse(json);
  return {
    result,
    vars,
  };
};

export default parseInitParams;
