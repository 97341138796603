import { handleActions } from 'redux-actions';

import { clearStateAction } from '../actions/global';
import {
  ibtStart,
  ibtSuccess,
  ibtError,
  ibtOfflineBankSelectAttemptAction,
  ibtCancelSelectionAction,
  ibtChangeSearchFilter,
} from '../actions/instantBankTransfer';

export const defaultState = {
  loading: false,
  error: false,
  banks: null,
  psp: null,
  offlineSelectedNoticeVisible: false,
  searchFilter: '',
};

const instantBankTransfer = handleActions(
  {
    [clearStateAction]: () => defaultState,
    [ibtStart as any]: (state) => ({
      ...state,
      loading: true,
      error: false,
      offlineSelectedNoticeVisible: false,
    }),
    [ibtSuccess as any]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [ibtError as any]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [ibtOfflineBankSelectAttemptAction as any]: (state) => ({
      ...state,
      offlineSelectedNoticeVisible: true,
    }),
    [ibtCancelSelectionAction as any]: (state) => ({
      ...state,
      offlineSelectedNoticeVisible: false,
    }),
    [ibtChangeSearchFilter as any]: (state, { payload }) => ({
      ...state,
      searchFilter: payload,
    }),
  } as any,
  defaultState,
);

export default instantBankTransfer;
