import { createAction } from 'redux-actions';

export const ccTokenisedPaymentStartAction = createAction(
  'CC_TOKENISED_PAYMENT_START',
);

export const ccTokenisedPaymentSuccessAction = createAction(
  'CC_TOKENISED_PAYMENT_SUCCESS',
  (status, body) => ({
    status,
    body,
  }),
);

export const ccTokenisedPaymentErrorAction = createAction(
  'CC_TOKENISED_PAYMENT_ERROR',
  (status, body) => ({
    status,
    body,
  }),
);

export const ccTokenisedPaymentAttemptSubmitFormAction = createAction(
  'CC_TOKENISED_PAYMENT_ATTEMPT_SUBMIT_FORM',
);

export const ccTokenisedPaymentCancelAction = createAction(
  'CC_TOKENISED_PAYMENT_CANCEL',
);

export const ccTokenisedPaymentAuthoriseForm = createAction(
  'CC_TOKENISED_PAYMENT_AUTHORISE_FORM',
);

export const ccTokenisedPaymentMethodRemoveCancelAction = createAction(
  'CC_TOKENISED_PAYMENT_METHOD_REMOVE_CANCEL',
);
export const ccTokenisedPaymentMethodRemoveConfirmAction = createAction(
  'CC_TOKENISED_PAYMENT_METHOD_REMOVE_CONFIRM',
);

export const ccTokenisedPaymentMethodRemoveStartAction = createAction(
  'CC_TOKENISED_PAYMENT_METHOD_REMOVE_START',
);
export const ccTokenisedPaymentMethodRemoveSuccessAction = createAction(
  'CC_TOKENISED_PAYMENT_METHOD_REMOVE_SUCCESS',
);
export const ccTokenisedPaymentMethodRemoveErrorAction = createAction(
  'CC_TOKENISED_PAYMENT_METHOD_REMOVE_ERROR',
);
