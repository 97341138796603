const createForm: (url: string) => HTMLFormElement = (url) => {
  const form: HTMLFormElement = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', url);
  form.style.display = 'none';
  return form;
};

const createInput: (key: string, value: string) => HTMLInputElement = (
  key,
  value,
) => {
  const input = document.createElement('input');
  input.name = key;
  input.value = value;
  return input;
};

const createFormWithInputs: (
  url: string,
  body: { [key: string]: string },
) => HTMLFormElement = (url, body) => {
  const form = createForm(url);
  Object.keys(body).forEach((key: string) =>
    form.appendChild(createInput(key, body[key])),
  );
  return form;
};

const postFormFn = (url: string, body: { [key: string]: string }) => {
  const bodyElement = document.querySelector('body');
  if (bodyElement) {
    const form = createFormWithInputs(url, body);
    bodyElement.appendChild(form);
    form.submit();
  }
};

// The returned promise is relevant only in cases of error, otherwise the page should redirect
const postForm: (
  url: string,
  body: { [key: string]: string },
) => Promise<void> = (url, body = {}) => {
  return Promise.resolve().then(() => {
    try {
      return postFormFn(url, body);
    } catch (err) {
      return Promise.reject(err);
    }
  });
};

export default postForm;
