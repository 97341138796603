import { combineActions, handleActions } from 'redux-actions';
import uuid from 'uuid/v4';

import {
  analyticsGenerateFrontEndAttemptIdAction,
  analyticsClearStateAction,
} from '../actions/analytics.ninja';
import { clearStateAction, clearPersistenceState } from '../actions/global';

export const defaultState = {
  frontEndAttemptID: null,
};

export default handleActions(
  {
    [combineActions(clearStateAction, clearPersistenceState) as any]: () =>
      defaultState,
    [analyticsClearStateAction as any]: () => defaultState,
    [analyticsGenerateFrontEndAttemptIdAction as any]: (
      state,
      { payload: frontendAttemptId },
    ) => ({
      ...state,
      frontEndAttemptID: frontendAttemptId || uuid(),
    }),
  } as any,
  defaultState,
);
