import { flow, get } from 'lodash/fp';
import { createSelector, createStructuredSelector } from 'reselect';

import getSearchParams from '../helpers/getSearchParams';
import parseInitParams from '../helpers/parseInitParams';
import { location, search as searchSelector } from './routing';

const initParams = createSelector(
  searchSelector,
  flow(parseInitParams, get('result')),
);

export const queryParams: any = createSelector(location, (loc: any) =>
  getSearchParams(loc.search || window.location.search),
);

const generic = get('generic');
export const populated = createSelector(generic, get('populated'));
export const version = createSelector(generic, get('version'));
export const status = createSelector(generic, get('status'));
export const options = createSelector(generic, get('options'));
export const context = createSelector(generic, get('context'));
export const offlineModalIsOpen = createSelector(
  generic,
  get('offlineModalIsOpen'),
);

export const paymentId = createSelector(initParams, get('paymentId'));
export const integrator = createSelector(initParams, get('integrator'));

export const action = createSelector(queryParams, get('act'));
export const psp = createSelector(queryParams, get('psp'));
export const shouldInitByConvention = createSelector(
  action,
  psp,
  (action, psp) => !!(action && psp),
);

export default createStructuredSelector({
  populated,
  version,
  status,
  psp,
  options,
  action,
  paymentId,
  integrator,
  offlineModalIsOpen,
  shouldInitByConvention,
});
