import { getOr } from 'lodash/fp';

import * as paths from '../routes/pathConstants';
import type { PaymentStatus } from '../types/PaymentStatus';

type PaymentStatusRedirectPaths = {
  [status in PaymentStatus]: string;
};

const paymentStatusRedirectPaths: Partial<
  PaymentStatusRedirectPaths & { default: string }
> = {
  WAITING_PSP_ACTION: paths.PAYMENT_SELECTION,
  default: paths.PAYMENT_PENDING,
};

const determinePaymentStatusRedirection = (status: PaymentStatus) =>
  getOr(paymentStatusRedirectPaths.default, status, paymentStatusRedirectPaths);

export default determinePaymentStatusRedirection;
