import { useEffect } from 'react';

import { useExternalScript } from '../useExternalScript';

interface INewRelic {
  setCustomAttribute: (t: string, options: any) => void;
  addPageAction: (t: string, options: any) => void;
  noticeError: (e: Error) => void;
}

export const useNewRelic = () => {
  const state = useExternalScript(
    `/js/new-relic-${process.env.REACT_APP_RUNTIME_ENVIRONMENT}.js`,
  );

  useEffect(() => {
    if (state === 'ready') {
      window.newrelic.setCustomAttribute('cUserAgent', navigator.userAgent);
    }
  }, [state]);

  return {
    newRelic: (window?.newrelic || {
      setCustomAttribute: () => '',
      addPageAction: () => '',
      noticeError: () => '',
    }) as INewRelic,
  };
};
