import analyticsSaga from './analytics';
import creditCardSaga from './creditCardSaga';
import recurringCardPayment from './creditCardSaga/recurringCardPaymentSaga';
import genericFlowSaga from './genericFlowSaga';
import googlePaySaga from './googlePaySaga';
import initSaga from './initSaga';
import instantBankTransferSaga from './instantBankTransferSaga';
import mainV2Saga from './mainV2Saga';
import paymentFlowSaga from './paymentFlowSaga';
import paymentMethodsSaga from './paymentMethodsSaga';
import paymentStatusSaga from './paymentStatusSaga';
import tokenisedPaymentSaga from './tokenisedPaymentSaga';

const allSagas = [
  initSaga,
  analyticsSaga,
  instantBankTransferSaga,
  paymentFlowSaga,
  tokenisedPaymentSaga,
  creditCardSaga,
  paymentStatusSaga,
  paymentMethodsSaga,
  genericFlowSaga,
  mainV2Saga,
  googlePaySaga,
  recurringCardPayment,
];

export default (sagaMiddleware) => {
  allSagas.forEach((saga) => sagaMiddleware.run(saga));
};
