import { get } from 'lodash/fp';
import { LOCATION_CHANGE } from 'react-router-redux';
import { Action } from 'redux-actions';
import { actionTypes as FormActionTypes } from 'redux-form';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as paths from '../../../routes/pathConstants';
import { cardType as cardTypeSelector } from '../../../selectors/creditCard';
import {
  integrator as integratorSelector,
  platform as platformSelector,
} from '../../../selectors/global';
import { EventTrackingArgs } from '../../../types/Tracker';
import {
  analyticsClearStateAction,
  analyticsTrackEventAction,
  analyticsTrackPageAction,
  analyticsTrackLinkAction,
} from '../../actions/analytics.ninja';
import { goBack } from '../../actions/global';
import tracker from './compositeTracker';
import { methodTypes } from './constants';
import { currentPageAndMethod, trackPageView, trackGoBack } from './shared';

const knownForms = {
  creditCard: methodTypes.creditCard,
  tokenisedPayment: methodTypes.tokenisedPayment,
};

const fieldNames = {
  nameoncard: 'name_on_card',
  ccnumber: 'credit_card_number',
  ccexpiry: 'credit_card_expiry',
  cvv: 'cvv',
  storeData: 'store_data',
};

const getFieldName = (field) => fieldNames[field] || field;

function* trackArbitraryEvent({
  payload: { eventName, params },
}: Action<EventTrackingArgs>): Generator<any, any, any> {
  const { methodType } = yield currentPageAndMethod();
  yield tracker.trackEvent({
    eventName,
    params: {
      ...params,
      methodType,
    },
  });
}

function* trackFormChanges({
  meta: { form, field },
}): Generator<any, any, any> {
  const value = yield select(get(`form.${form}.values.${field}`));
  const integrator = yield select(integratorSelector);
  const platform = yield select(platformSelector);
  const formName = knownForms[form];
  const fieldName = getFieldName(field);

  if (form === 'creditCard' && field === 'storeData') {
    yield tracker.trackEvent({
      eventName: 'Card_Store_Checkbox',
      params: {
        methodType: formName,
        fieldName,
        platform,
        integrator,
        checked: !!value,
      },
    });
  }
}

function* trackFormFieldValue({
  meta: { form, field },
}): Generator<any, any, any> {
  const formName = knownForms[form];
  if (!formName || field === 'storeData') {
    return;
  }

  const errors = yield select(get(`form.${form}.syncErrors.${field}`));
  const value = yield select(get(`form.${form}.values.${field}`));
  const fieldName = getFieldName(field);
  const cardType = yield select(cardTypeSelector);

  if (errors && value) {
    yield tracker.trackEvent({
      eventName: 'Card_Field_Error',
      params: {
        fieldName,
        methodType: formName,
      },
    });
  }

  yield tracker.trackEvent({
    eventName: 'Card_Field_Filled',
    params: {
      cardType,
      fieldName,
      field_is_valid: !errors,
      field_length: value ? value.length : 0,
      isEmpty: !value,
      methodType: formName,
    },
  });
}

function* trackLink({ payload }) {
  const { pageName, methodType } = yield currentPageAndMethod();
  yield tracker.trackEvent({
    eventName: `${pageName}_Link_Selected`,
    params: {
      ...payload,
      methodType,
    },
  });
}

function* analyticsClearState() {
  yield put(analyticsClearStateAction());
}

const paymentSelectionVisited = ({ type, payload }) =>
  type === LOCATION_CHANGE && payload.pathname === paths.PAYMENT_SELECTION;

const makeMainSaga = ({ behavior }) =>
  function* sharedAnalyticsSaga() {
    yield takeLatest(FormActionTypes.CHANGE, behavior.trackFormChanges);
    yield takeLatest(FormActionTypes.BLUR, behavior.trackFormFieldValue);

    yield takeLatest(analyticsTrackPageAction, behavior.trackPageView);

    yield takeLatest(goBack, behavior.trackGoBack);

    yield takeLatest(analyticsTrackEventAction, behavior.trackArbitraryEvent);

    yield takeLatest(analyticsTrackLinkAction, behavior.trackLink);

    yield takeLatest(
      paymentSelectionVisited as any,
      behavior.analyticsClearState,
    );
  };

export default makeMainSaga({
  behavior: {
    analyticsClearState,
    trackFormChanges,
    trackFormFieldValue,
    trackPageView,
    trackGoBack,
    trackArbitraryEvent,
    trackLink,
  },
});

export const behaviorForTesting = {
  trackFormChanges,
  trackFormFieldValue,
  trackPageView,
  trackArbitraryEvent,
  trackLink,
};
