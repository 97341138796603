import { getOr } from 'lodash/fp';
import { combineActions, handleActions } from 'redux-actions';

import {
  ccStartAction,
  ccSuccessAction,
  ccErrorAction,
  ccRedirectAction,
  ccPaymentStartAction,
  ccPaymentSuccessAction,
  ccPaymentErrorAction,
} from '../actions/creditCard';
import { clearStateAction } from '../actions/global';
import { psTimeoutAction, psErrorAction } from '../actions/paymentStatus';
import {
  ccTokenisedPaymentSuccessAction,
  ccTokenisedPaymentErrorAction,
} from '../actions/tokenisedPayment';

export const defaultState = {
  customerId: false,
  sessionId: false,
  loading: false,
  confirming: false,
  error: false,
  paymentState: false,
};

const creditCard = handleActions(
  {
    [clearStateAction]: () => defaultState,
    [ccStartAction as any]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [ccSuccessAction as any]: (state, { payload }) => {
      const cardPaymentPaymentInitialization = getOr(
        false,
        'cardPaymentPaymentInitialization',
        payload,
      );
      return {
        ...state,
        paymentInfo: payload,
        sessionId: getOr(
          false,
          'clientSessionId',
          cardPaymentPaymentInitialization,
        ),
        customerId: getOr(
          false,
          'customerId',
          cardPaymentPaymentInitialization,
        ),
        loading: false,
        error: false,
      };
    },
    [ccErrorAction as any]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [ccPaymentStartAction as any]: (state) => ({
      ...state,
      lading: false,
      confirming: true,
      error: false,
    }),
    [ccRedirectAction as any]: (state) => ({
      ...state,
      confirming: false,
    }),
    [ccPaymentSuccessAction as any]: (state) => ({
      ...state,
      confirming: false,
    }),

    // TODO: This condition is never reached, because `ccPaymentErrorAction`
    // is also handled below. Needs test to make sure that we can remove it
    /* istanbul ignore next */
    [ccPaymentErrorAction as any]: (state) => ({
      ...state,
      confirming: false,
    }),
    [combineActions(
      ccPaymentSuccessAction,
      ccTokenisedPaymentSuccessAction,
    ) as any]: (state, { payload: { status, body } }: any = {}) => ({
      ...state,
      paymentState: status,
      resultBody: body,
      loading: false,
      error: false,
    }),
    [combineActions(
      ccPaymentErrorAction,
      ccTokenisedPaymentErrorAction,
      psTimeoutAction,
      psErrorAction,
    ) as any]: (state, { payload: { status } }: any = {}) => ({
      ...state,
      paymentState: status,
      loading: false,
      error: true,
      confirming: false,
    }),
  },
  defaultState,
);

export default creditCard;
