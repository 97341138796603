export type PaymentStatus = keyof typeof PaymentStatusLabels;

export enum PaymentStatusLabels {
  REJECTED = 'REJECTED',
  ERROR = 'ERROR',
  CAPTURED = 'CAPTURED',
  PENDING_EXECUTION = 'PENDING_EXECUTION',
  WAITING_CUSTOMER_ACTION = 'WAITING_CUSTOMER_ACTION',
  WAITING_PSP_ACTION = 'WAITING_PSP_ACTION',
}
