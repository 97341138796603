import getAppId from '../helpers/getAppId';
import parseInitParams from '../helpers/parseInitParams';
import importByReflection from './importByReflection';

const getLocale = (): string => {
  const { result } = parseInitParams();
  const locale = result?.locale.replace('_', '-').toLowerCase();

  return locale || 'en-US';
};

type AppConfig = {
  [key: string]: any;
  isRTL: boolean;
  lang: string;
  links: any[];
};

export const getAppConfig = async (): Promise<AppConfig> => {
  try {
    const locale = getLocale();
    const [lang] = locale.split('-');
    const appId = getAppId();

    const [appConfigModule, links] = await Promise.all([
      importByReflection(appId, locale, 'formats'),
      importByReflection(appId, locale, 'links'),
    ]);

    return {
      ...appConfigModule,
      isRTL: false,
      lang,
      links,
    };
  } catch (err) {
    throw new Error(`Error while parsing init params: ${err}`);
  }
};
