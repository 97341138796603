import { InternalAxiosRequestConfig } from 'axios';
import { merge } from 'lodash';

import getAppId from '../helpers/getAppId';

export const browserInfoInterceptor = (config: InternalAxiosRequestConfig) => {
  const urlCheck = /adyen\/(execute|initialize)/g;

  if (urlCheck.test(config?.url || '')) {
    return merge(config, {
      headers: {
        'Browser-Language': navigator.language,
        'Browser-Javascript-Enabled': 'true',
        'Browser-Java-Enabled': 'false',
        'Browser-Width': screen.width.toString(),
        'Browser-Height': screen.height.toString(),
        'Browser-Color-Depth': screen.colorDepth.toString(),
        'Browser-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'Browser-Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        'X-Client-User-Agent': navigator.userAgent,
      },
    });
  }

  return config;
};

export const authenticationInterceptor = (
  config: InternalAxiosRequestConfig,
  token: string | undefined,
) => {
  if (token) {
    return merge(config, { headers: { Authorization: `Bearer ${token}` } });
  }

  return config;
};

export const idempotencyInterceptor = (
  config: InternalAxiosRequestConfig,
  idempotencyId: string,
) => {
  const urlCheck = /(execute|initialize)/g;

  if (urlCheck.test(config?.url || '')) {
    return merge(config, {
      headers: { 'X-Plutus-Idempotency-Key': idempotencyId },
    });
  }

  return config;
};

export const plutusIdInterceptor = (config: InternalAxiosRequestConfig) => {
  return merge(config, {
    headers: { 'Plutus-App-Id': getAppId() },
  });
};
