import { createAction } from 'redux-actions';

export const psRequestStatusAction = createAction('PS_REQUEST_STATUS');
export const psContinueRequestStatusAction = createAction('PS_CONTINUE');
export const psStartAction = createAction('PS_START');
export const psSuccessAction = createAction('PS_SUCCESS');
export const psTimeoutAction = createAction('PS_TIMEOUT');
export const psClearTimeoutAction = createAction('PS_CLEAR_TIMEOUT');
export const psStartTokenisedFlowAction = createAction('PS_START_TOKEN');
export const psErrorAction = createAction('PS_ERROR');
export const psSetMethodAction = createAction('P_SETMETHOD');
export const psPollForStatusAction = createAction('PS_POLL_FOR_STATUS');
export const psAuthoriseFormAction = createAction('PS_AUTHORISE_FORM');
export const psResetTimeout = createAction('PS_RESET_TIMEOUT');
