export const createForm = (url: string): HTMLFormElement => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = url;
  form.style.display = 'none';
  return form;
};

export const createInput = (key: string, value: string): HTMLInputElement => {
  const input = document.createElement('input');
  input.name = key;
  input.value = value;
  return input;
};

export const createFormWithInputs = (
  url: string,
  body: { [key: string]: string },
): HTMLFormElement => {
  const form = createForm(url);
  Object.entries(body).forEach(([key, value]) => {
    form.appendChild(createInput(key, value));
  });
  return form;
};

export const postForm = async (
  url: string,
  body: { [key: string]: string } = {},
) => {
  try {
    const bodyElement = document.querySelector('body');
    if (bodyElement) {
      const form = createFormWithInputs(url, body);
      bodyElement.appendChild(form);
      form.submit();
    }
  } catch (err) {
    throw new Error('Failed to submit form');
  }
};
