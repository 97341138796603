import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import rootReducer from './reducers';
import runSagas from './sagas';

export const history = createHistory();

export const getState = () => {
  return store ? store.getState() : {};
};

function configureStore(runSagas) {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const storage = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(thunk, sagaMiddleware, routerMiddleware(history)),
    ),
  );

  runSagas(sagaMiddleware);

  return storage;
}

export const store = configureStore(runSagas);
export const persistor = persistStore(store);

export type TRootState = ReturnType<typeof store.getState>;

export type TAppDispatch = typeof store.dispatch;
