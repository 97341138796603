import { ApiV2Response } from '../types/ApiV2Response';
import { TApiVersions } from '../types/ApiVersions';

export const ApiVersions: Record<TApiVersions, TApiVersions> = {
  V1: 'V1',
  V2: 'V2',
  'V2.1': 'V2.1',
  'V2.2': 'V2.2',
};

export const isOneOfApiVersions =
  (versions: TApiVersions[]) => (version: TApiVersions) =>
    versions.includes(version);
