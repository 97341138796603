import type { Tracker } from '../../../types/Tracker';
import compositeTracker from './compositeTracker';
import { currentPageAndMethod } from './shared';

// TODO get online property form bank argument.
const trackBankSelected = (
  bankOnline: boolean,
  tracker: Tracker = compositeTracker,
) =>
  function* doTrackBankSelected(bank, searchQuery): Generator<any, any, any> {
    const { methodType } = yield currentPageAndMethod();

    yield tracker.trackEvent({
      eventName: 'Payment_Submitted',
      params: {
        formValid: bankOnline,
        methodType,
        bankType: bank.issuerId,
        ...(searchQuery ? { searchQuery } : undefined),
      },
    });
  };

export const trackOnlineBankSelected = trackBankSelected(true);
export const trackOfflineBankSelected = trackBankSelected(false);

export const behaviorForTesting = {
  trackBankSelected,
};
