import { handleActions } from 'redux-actions';

import {
  genClear,
  genInitializeStart,
  genInitializeSuccess,
  selectOfflineBankAction,
  onChangeFilter,
  closeOfflineBankNotice,
} from '../actions/generic';

type State = {
  populated: boolean;
  version?: string;
  status?: string;
  psp?: string;
  options?: Array<any>;
  description?: string;
  grossValue?: number;
  smsSurcharge?: number;
  currency?: string;
  offlineModalIsOpen?: boolean;
  target?: any;
};

export const defaultState: State = {
  populated: false,
  searchFilter: '',
  offlineModalIsOpen: false,
} as any;

export default handleActions(
  {
    [genClear as any]: () => defaultState,
    [genInitializeStart as any]: (state) => ({
      ...state,
      offlineModalIsOpen: false,
    }),
    [genInitializeSuccess as any]: (
      state,
      {
        payload: {
          version,
          status,
          psp,
          options,
          context: {
            grossValue,
            smsSurcharge,
            currency,
            description,
          } = {} as any,
        },
      }: any = {},
    ) => ({
      ...state,
      populated: true,
      version,
      status,
      psp,
      options,
      grossValue,
      smsSurcharge,
      currency,
      description,
    }),
    [selectOfflineBankAction as any]: (state) => ({
      ...state,
      offlineModalIsOpen: true,
    }),
    [closeOfflineBankNotice as any]: (state) => ({
      ...state,
      offlineModalIsOpen: false,
    }),
    [onChangeFilter as any]: (
      state,
      {
        payload: {
          target: { value },
        },
      },
    ) => ({
      ...state,
      searchFilter: value || '',
    }),
  },
  defaultState,
);
