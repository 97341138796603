import { useEffect, useState } from 'react';

import { EventShimType } from '@nexus/lib-react/dist/common/InputSynthetic';
import { useIntl } from 'react-intl';

type TPhoneState = {
  countryCode: string;
  phoneNumber: string;
  isValid?: boolean;
  hasChanged?: boolean;
};

type TUsePhoneNumberReturn = {
  inputValues: TPhoneState;
  handlePhoneNumberChange: (event: EventShimType<TPhoneState>) => void;
  getPhoneNumberErrorMessage: () => string | undefined;
};

const phoneRegexPatterns = {
  351: /^9[1236]\d{7}$/, //PT
};

export const usePhoneNumber = (
  defaultValues: TPhoneState = {
    isValid: false,
    hasChanged: false,
    countryCode: '',
    phoneNumber: '',
  },
): TUsePhoneNumberReturn => {
  const { formatMessage } = useIntl();
  const [inputValues, setInputValues] = useState<TPhoneState>(defaultValues);

  useEffect(() => {
    if (defaultValues.phoneNumber) {
      handlePhoneNumberChange({
        target: { value: defaultValues },
      } as unknown as EventShimType<TPhoneState>);
    }
  }, [defaultValues.phoneNumber]);

  const handlePhoneNumberChange = (event: EventShimType<TPhoneState>) => {
    const { phoneNumber = '', countryCode } = event.target.value;

    const isValid = validatePhoneNumber(phoneNumber, countryCode);

    setInputValues({
      ...event.target.value,
      phoneNumber,
      isValid,
      hasChanged: true,
    });
  };

  const validatePhoneNumber = (
    phoneNumber: string,
    countryCode?: string,
  ): boolean => {
    const phoneRegex = phoneRegexPatterns[countryCode || ''];

    if (!phoneRegex) {
      return false;
    }

    return phoneRegex.test(phoneNumber);
  };

  const getPhoneNumberErrorMessage = (): string | undefined => {
    const { phoneNumber, isValid } = inputValues;

    if (!phoneNumber) {
      return formatMessage({
        id: 'app.common.phone_number.phone_required',
        defaultMessage: 'Phone number is required',
      });
    }

    if (!isValid) {
      return formatMessage({
        id: 'app.common.phone_number.phone_invalid',
        defaultMessage: 'Phone number is invalid',
      });
    }

    return undefined;
  };

  return {
    inputValues,
    handlePhoneNumberChange,
    getPhoneNumberErrorMessage,
  };
};
