import React, { FC, createContext, useContext } from 'react';

import { IntlProvider } from 'react-intl';

import FullPageLoader from '../../components/FullPageLoader';
import { useLanguageRequest } from '../../hooks';
import { AppContext } from '../AppContext';

export const LanguageContext = createContext({});

export const LanguageProvider: FC = ({ children }) => {
  const {
    parsedParams: {
      init: { locale },
    },
  } = useContext(AppContext);

  const [lang] = locale.split('_');

  const { data: messages, loading } = useLanguageRequest(locale);

  return (
    <LanguageContext.Provider value={{ messages }}>
      <IntlProvider messages={messages} locale={lang}>
        <FullPageLoader isOpen={loading} />
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
