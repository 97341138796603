import { apiAction, orchestrationHost } from './shared';

export const getRecurringPaymentInfo = ({
  recurringPaymentId,
  token,
  integrator,
}: any = {}) => {
  const additionalHeaders = {
    Authorization: `Bearer ${token}`,
  };

  return apiAction(
    {
      httpMethod: 'PATCH',
      uri: `${orchestrationHost(
        integrator,
      )}/recurring-payments/${recurringPaymentId}/payment-method`,
    } as any,
    {
      additionalHeaders: additionalHeaders,
    },
  );
};
