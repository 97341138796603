import { createAction } from 'redux-actions';

export const analyticsTrackPageAction = createAction('ANALYTICS_TRACK_PAGE');
export const analyticsTrackEventAction = createAction('ANALYTICS_TRACK_EVENT');
export const analyticsTrackLinkAction = createAction('ANALYTICS_TRACK_LINK');

export const analyticsTrackPageSuccessAction = createAction(
  'ANALYTICS_TRACK_PAGE_SUCCESS',
);
export const analyticsTrackEventSuccessAction = createAction(
  'ANALYTICS_TRACK_EVENT_SUCCESS',
);
export const analyticsTrackPageErrorAction = createAction(
  'ANALYTICS_TRACK_PAGE_ERROR',
);
export const analyticsTrackEventErrorAction = createAction(
  'ANALYTICS_TRACK_EVENT_ERROR',
);

export const analyticsGenerateFrontEndAttemptIdAction = createAction(
  'ANALYTICS_GENERATE_FRONT_END_ATTEMPT_ID',
);

export const analyticsClearStateAction = createAction('ANALYTICS_CLEAR_STATE');
