export enum EImageProviders {
  local = '/images',
  banks = '/images/banks',
  adyen = 'https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/medium',
  self = '',
}

export enum EImageExtension {
  png = 'png',
  svg = 'svg',
}

export const getPaymentMethodImage = (
  provider: EImageProviders,
  image: string,
  extension: EImageExtension,
) => {
  if (provider === EImageProviders.self) {
    return image;
  }

  return `${provider}/${image}.${extension}`;
};
