import Axios, { InternalAxiosRequestConfig } from 'axios';
import { configure } from 'axios-hooks';
import uuid from 'uuid/v4';

import getAppId from '../helpers/getAppId';
import {
  authenticationInterceptor,
  browserInfoInterceptor,
  idempotencyInterceptor,
  plutusIdInterceptor,
} from './requestInterceptors';
import { responseHandlerInterceptor } from './responseInterceptors';

export const orchestrationUrls = {
  // Europe
  default: process.env.REACT_APP_EUROPE_ORCHESTRATION_URL,
};

const getOrchestrationHost = (appId = '') => {
  return orchestrationUrls[appId] ?? orchestrationUrls.default;
};

type configureBaseRequestProps = { token?: string };

export const generateIdempotency = () => uuid();

export const configureBaseRequest = ({ token }: configureBaseRequestProps) => {
  const idempotency = generateIdempotency();

  const axios = Axios.create({
    baseURL: getOrchestrationHost(getAppId()),
    withCredentials: true,
  });

  const requestInterceptors = [
    (config: InternalAxiosRequestConfig) =>
      idempotencyInterceptor(config, idempotency),
    (config: InternalAxiosRequestConfig) =>
      authenticationInterceptor(config, token),
    plutusIdInterceptor,
    browserInfoInterceptor,
  ];

  for (const interceptor of requestInterceptors) {
    axios.interceptors.request.use(interceptor);
  }

  axios.interceptors.response.use(
    responseHandlerInterceptor,
  );

  configure({ axios });
};
