import { createAction } from 'redux-actions';

export const setLoading = createAction('SHOW_LOADING');
export const stopLoading = createAction('HIDE_LOADING');
export const paymentFlowFinishedAction = createAction('PAYMENT_FLOW_FINISHED');
export const clearStateAction: any = createAction('CLEAR_STATE');
export const clearPersistenceState = createAction('CLEAR_PERSISTENCE_STATE');
export const setGlobals = createAction('SET_GLOBALS');
export const cancelExperienceAction = createAction('CANCEL_EXPERIENCE');
export const applicationInitializedAction = createAction(
  'APPLICATION_INITIALIZED',
);
export const setEnvVarsAction = createAction('SET_ENVIRONMENT_VARS');
export const goBack = createAction('GO_BACK');
