import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

import OLoader from '@olxui/core-v10/dist/core/Loading/OLoader';
import XLoader from '@olxui/core-v10/dist/core/Loading/XLoader';

import { generateSecureRandomNumber } from '../../helpers/generateSecureRandomNumber';

type Props = {
  children?: React.ReactElement<any>;
  isOpen: boolean;
};

export const FullPageLoader = ({ isOpen }: Props) =>
  isOpen ? (
    <FixedContainer>
      <BackDrop />
      <Animation />
    </FixedContainer>
  ) : null;

export default FullPageLoader;

const Animation = () => {
  const [animationComponent, setAnimationComponent] =
    useState<null | React.ReactElement>(null);

  useEffect(() => {
    (async () => {
      const randomValue = generateSecureRandomNumber();
      setAnimationComponent(randomValue < 0.5 ? <OLoader /> : <XLoader />);
    })();
  }, []);

  return animationComponent;
};

const overlay = `
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

const FixedContainer = styled.div`
  ${overlay}
  z-index: 1051;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    z-index: 2;
  }
`;

const BackDrop = styled.span`
  opacity: 0.9;
  background-color: ${({ theme }: any) =>
    theme.colors['background-global-secondary']};
  width: 100vw;
  height: 100vh;
  position: absolute;
`;
