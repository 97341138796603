/* eslint-disable prefer-const */
import { select, call } from 'redux-saga/effects';

import { EPaymentTypes } from '../../../constants/paymentTypes';
import getAppId from '../../../helpers/getAppId';
import getPspKey from '../../../helpers/getPspKey';
import { getPaymentProviderInstance } from '../../../paymentProviders';
import { frontEndAttemptID as frontEndAttemptIdSelector } from '../../../selectors/analytics';
import {
  formValues,
  paymentInfo as paymentInfoSelector,
  creditCardSelector,
  paymentDetails as paymentDetailsSelector,
  trimmedCardNumber,
  cardType,
  psp as pspSelector,
} from '../../../selectors/creditCard';
import {
  paymentId as paymentIdSelector,
  locale as localeSelector,
  pspQuerySelector,
  paymentTypeSelector,
} from '../../../selectors/global';

export function* getPaymentExecutionParameters() {
  const paymentId = yield select(paymentIdSelector);
  const paymentInfo = yield select(paymentInfoSelector);
  const details = yield select(paymentDetailsSelector);
  const locale = yield select(localeSelector);
  const { customerId, sessionId } = yield select(creditCardSelector);
  const psp = (yield select(pspSelector)) || (yield select(pspQuerySelector));
  const cardNumber = yield select(trimmedCardNumber);
  const paymentProvider = yield getPaymentProviderInstance(psp);
  const integrator = getAppId();
  const subtype = yield select(cardType);
  const paymentType = yield select(paymentTypeSelector);

  let {
    ccexpiry: expiryDate,
    nameoncard: name,
    cvv,
    storeData,
  } = yield select(formValues);

  if (storeData === undefined && paymentInfo.context?.tokenizeByDefault) {
    storeData = paymentInfo.context.tokenizeByDefault;
  } else if (paymentType === EPaymentTypes.RECURRING) {
    storeData = true;
  }

  const frontendAttemptId = yield select(frontEndAttemptIdSelector);
  const pspKey = yield call(getPspKey, integrator, psp);

  const encryptedData = yield paymentProvider.makePayment({
    pspKey,
    details,
    customerId,
    locale,
    paymentId,
    paymentInfo,
    sessionId,
    cardDetails: {
      cardNumber,
      expiryDate,
      name,
      cvv,
      storeData,
    },
  });

  return {
    ...encryptedData,
    storeData: !!storeData,
    subtype,
    frontendAttemptId,
  };
}

export function* getPaymentDetailsParameters() {
  const paymentId = yield select(paymentIdSelector);

  return {
    paymentId,
  };
}
