const KEYS = {
  'ua-PORTMONE':
    'c63e20e1f27444b870a2fef9984f8b679df7c04a1bc10ffb3539d9ddb0581502a4113a116a85965d77ea614c8975d92a89b9d3de1aacc51ff7351746086f772b7e04a587f5a3c8fd2ddf3391c262561826e9a83b508961761d37f6d594f755f92862a356e093598c3269655e7f8b086f3faa41f71472699db8db815bd652eb56bbe9e272183963b1aa9851be3e8603325310b5d1e56efcbf7e4d15c155525248f1d65de3ef50eecee99676ee906025ce84f3aabaf8637e51429b6b7bdbeb171c403811a6e644007697e0d738720c206aa36c0d71ceacc7b3bfd52b16868748e6bcdb3addc01c504ea39c359776ce12202b2215aaf6e194ea8bd65343e7dcd731',
};

export function generateGetPspKey(keys) {
  return function getPspKey(integrator, psp) {
    const ucPSP = psp.toUpperCase();
    const key = `${integrator}-${ucPSP}`;

    return keys[key] || false;
  };
}

export default generateGetPspKey(KEYS);
