// This reducer is responsible for blocking submitting payment forms.
import { combineActions, handleActions } from 'redux-actions';

import {
  ccPaymentStartAction,
  ccPaymentErrorAction,
  ccPaymentSuccessAction,
} from '../actions/creditCard';
import { clearStateAction } from '../actions/global';
import { psTimeoutAction, psErrorAction } from '../actions/paymentStatus';
import {
  ccTokenisedPaymentStartAction,
  ccTokenisedPaymentErrorAction,
  ccTokenisedPaymentSuccessAction,
} from '../actions/tokenisedPayment';

export const defaultState = true;

export default handleActions(
  {
    [clearStateAction]: () => defaultState,
    [combineActions(
      ccPaymentStartAction,
      ccTokenisedPaymentStartAction,
    ) as any]: () => false,
    [combineActions(
      ccPaymentSuccessAction,
      ccPaymentErrorAction,
      ccTokenisedPaymentSuccessAction,
      ccTokenisedPaymentErrorAction,
      psTimeoutAction,
      psErrorAction,
    ) as any]: () => true,
  },
  defaultState,
);
