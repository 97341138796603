import React, { createContext, FC, useContext } from 'react';

import { configureBaseRequest } from '../../api';
import { getUrlParams } from '../../helpers';

export const AppContext = createContext<ReturnType<typeof getUrlParams>>(
  {} as any,
);

export const useAppContext = () => useContext(AppContext);

export const AppProvider: FC = ({ children }) => {
  const params = getUrlParams();

  configureBaseRequest({ token: params.queryParams?.token });

  return (
    <AppContext.Provider value={{ ...params }}>{children}</AppContext.Provider>
  );
};
