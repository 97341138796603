import { combineActions, handleActions } from 'redux-actions';

import { ApiCallOptions } from '../../types/ApiCallOptions';
import type { PaymentMethod } from '../../types/PaymentMethod';
import {
  ccPaymentStartAction,
  ccPaymentErrorAction,
  ccPaymentSuccessAction,
} from '../actions/creditCard';
import { clearStateAction, clearPersistenceState } from '../actions/global';
import {
  psStartAction,
  psSuccessAction,
  psErrorAction,
  psTimeoutAction,
  psClearTimeoutAction,
  psStartTokenisedFlowAction,
  psSetMethodAction,
  psPollForStatusAction,
  psResetTimeout,
} from '../actions/paymentStatus';
import {
  ccTokenisedPaymentCancelAction,
  ccTokenisedPaymentStartAction,
  ccTokenisedPaymentErrorAction,
  ccTokenisedPaymentSuccessAction,
} from '../actions/tokenisedPayment';

// TODO trim redundant properties from this fat boi.
type State = {
  status: boolean;
  loading: boolean;
  error: boolean;
  currentMethod?: PaymentMethod;
  isModalOpen: boolean;
  interstitalVisible: boolean;
  allowPayment: boolean;
  statusRequestConfig?: ApiCallOptions;
  timedOut: boolean;
  paymentPendingVisible: boolean;
};

export const defaultState: State = {
  status: false,
  loading: false,
  error: false,
  currentMethod: undefined,
  isModalOpen: false,
  paymentPendingVisible: false,
  allowPayment: true,
  statusRequestConfig: undefined,
  timedOut: false,
} as any;

export default handleActions(
  {
    [combineActions(clearStateAction, clearPersistenceState) as any]: () =>
      defaultState,
    [ccTokenisedPaymentCancelAction as any]: (state) => ({
      ...state,
      loading: false,
      error: false,
      currentMethod: undefined,
    }),
    [psResetTimeout as any]: (state) => ({
      ...state,
      timedOut: false,
    }),
    [psSetMethodAction as any]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      currentMethod: payload,
      timedOut: false,
    }),
    [psStartAction as any]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [psSuccessAction as any]: (state, { payload }) => ({
      ...state,
      status: payload,
      loading: false,
      error: false,
    }),
    [psErrorAction as any]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [psStartTokenisedFlowAction as any]: (state) => ({
      ...state,
      isModalOpen: true,
    }),
    [psTimeoutAction as any]: (state, { payload = {} }: any = {}) => ({
      ...state,
      status: payload,
      paymentPendingVisible: payload.status !== 'WAITING_PSP_ACTION',
      timedOut: true,
    }),
    [combineActions(
      psClearTimeoutAction,
      psSuccessAction,
      psErrorAction,
      psStartAction,
    ) as any]: (state) => ({
      ...state,
      paymentPendingVisible: false,
    }),
    [combineActions(
      ccPaymentStartAction,
      ccTokenisedPaymentStartAction,
    ) as any]: (state) => ({
      ...state,
      allowPayment: false,
    }),
    [ccTokenisedPaymentStartAction as any]: (state) => ({
      ...state,
      confirmingTokenized: true,
    }),
    [combineActions(
      ccPaymentSuccessAction,
      ccPaymentErrorAction,
      ccTokenisedPaymentSuccessAction,
      ccTokenisedPaymentErrorAction,
    ) as any]: (state) => ({
      ...state,
      allowPayment: true,
      confirmingTokenized: false,
    }),
    [psPollForStatusAction as any]: (state, { payload }: any = {}) => ({
      ...state,
      statusRequestConfig: {
        ...payload,
        // TODO discuss this with backend, we need to unify the uri property naming.
        url: payload.url || payload.uri || payload.pollUri,
      },
    }),
  } as any,
  defaultState,
);
