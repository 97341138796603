import React from 'react';

import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { getTitleAndSubtitle } from '../../helpers/route';
import { useIntegratorTranslation } from '../../hooks/useIntegratorTranslation';
import { getTheme } from '../../styles';

type HelmetComponentProps = {
  lang: string;
  isRTL: boolean;
};

export const HelmetComponent = ({ lang, isRTL }: HelmetComponentProps) => {
  const { formatMessage } = useIntl();

  const { pathname } = window.location;

  const theme = getTheme();
  const faviconHref = theme?.staticConfig?.favIcon;

  const { id, description, defaultMessage } = getTitleAndSubtitle(pathname);

  const titleKey = useIntegratorTranslation('app.title');

  const descriptionKey = useIntegratorTranslation(
    (description as string) || 'app.about_page.about_us.text',
  );

  let pageTitle = `${formatMessage({ id: titleKey })}`;

  if (id) {
    pageTitle = `${pageTitle} | ${formatMessage({
      id,
      defaultMessage,
    })}`;
  }

  return (
    <Helmet>
      <html lang={lang} dir={isRTL ? 'rtl' : 'ltr'} />
      <link rel="icon" href={faviconHref} />
      <meta
        name="description"
        content={formatMessage({ id: descriptionKey })}
      />
      <title> {pageTitle} </title>
    </Helmet>
  );
};
