import { reduce as cappedReduce } from 'lodash/fp';

const reduce = (cappedReduce as any).convert({ cap: false });
const populateParams = (url: string, params: { [key: string]: string }) => {
  if (typeof url === 'string') {
    return reduce(
      (mutatedTo, pValue, pKey) => {
        return mutatedTo.replace(new RegExp(`:${pKey}`, 'g'), pValue);
      },
      url,
      params,
    );
  }
  return url;
};

export default populateParams;
