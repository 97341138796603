import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as concreteApi from '../../../api';
import { paymentInfo as paymentInfoSelector } from '../../../selectors/creditCard';
import {
  integrator as integratorSelector,
  recurringPaymentIdSelector,
} from '../../../selectors/global';
import { updateTokenSelector } from '../../../selectors/routing';
import {
  ccCardErrorAction,
  ccErrorAction,
  ccPaymentErrorAction,
  ccPaymentSuccessAction,
  ccSuccessAction,
  getRecurringPaymentAction,
  recurringPaymentStartAction,
} from '../../actions/creditCard';
import { setGlobal, setGlobal as setError } from '../../actions/error';
import { paymentFlowFinishedAction } from '../../actions/global';
import * as sharedAnalyticsService from '../analytics/shared';
import * as paymentFlowService from '../paymentFlowService';
import { getPaymentExecutionParameters } from './shared';

function* updatePaymentMethod({ api }) {
  const integrator = yield select(integratorSelector);
  const recurringPaymentId = yield select(recurringPaymentIdSelector);
  const updateToken = yield select(updateTokenSelector);

  const apiCallOptions = {
    token: updateToken,
    recurringPaymentId,
    integrator,
  };

  try {
    const { result: { status } = { status: 400 }, json } =
      yield api.getRecurringPaymentInfo(apiCallOptions);

    if (status >= 400) {
      yield put(setGlobal(json));
      yield put(
        ccErrorAction({
          ...json,
          status,
        }),
      );
    } else {
      yield put(ccSuccessAction(json));
    }
  } catch (err) {
    throw err;
  }
}

function* makePayment({ analytics, api, paymentFlow }) {
  const {
    id,
    cardData: encryptedCardData,
    storeData,
    subtype,
  } = yield getPaymentExecutionParameters();

  const paymentInfo = yield select(paymentInfoSelector);
  const apiCallOptions = {
    ...paymentInfo,
    httpMethod: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const { result: { status } = { status: 400 }, json } =
      yield api.executePayment(
        {
          id,
          encryptedCardData,
          storeData,
          subtype,
        },
        apiCallOptions,
      );

    // legacy compatibility
    const action =
      json.actions && json.actions.submit ? json.actions.submit : json.action;

    if (action) {
      switch (action.type) {
        case 'REDIRECT':
          yield call(paymentFlow.redirect, { payload: { url: action.uri } });
          break;

        case 'REDIRECT_FORM':
          const flowFinishedAction = yield call(paymentFlowFinishedAction, {
            requestUrl: action.uri,
            request: action.params,
          });

          yield call(paymentFlow.redirectViaFormSubmit, flowFinishedAction);
          break;

        default:
          const successAction = (ccPaymentSuccessAction as any)(status, json);
          yield analytics.trackPaymentSuccess(successAction);
          yield put(successAction);
          yield put(
            paymentFlowFinishedAction({
              type: 'CARD',
            }),
          );
          yield paymentFlow.handlePaymentFlowFinished(successAction);
          break;
      }
    } else {
      const errorAction1 = (ccPaymentErrorAction as any)(status, json);
      const errorAction = (ccCardErrorAction as any)(status, json);
      yield analytics.trackPaymentFailure(errorAction);
      yield put(errorAction1);
      yield put(errorAction);
    }
  } catch (err) {
    const errorAction = (ccPaymentErrorAction as any)(0, err);
    // TODO refactor to be handled in error reducer
    console.error(err);
    yield analytics.trackPaymentFailure(errorAction);
    yield put(setError('generic'));
    yield put(errorAction);
  }
}

const makeMainSaga = ({ behavior, ...params }) =>
  function* creditCardSagaV2(): Generator<any, any, any> {
    yield takeLatest(
      getRecurringPaymentAction,
      behavior.updatePaymentMethod,
      params,
    );
    yield takeLatest(recurringPaymentStartAction, behavior.makePayment, params);
  };

export default makeMainSaga({
  analytics: sharedAnalyticsService,
  api: concreteApi,
  behavior: {
    updatePaymentMethod,
    makePayment,
  },
  paymentFlow: paymentFlowService,
});

export const behaviorForTesting = {
  updatePaymentMethod,
  makeMainSaga,
  makePayment,
};
