import { stringify } from 'query-string';

import getSearchParams from '../getSearchParams';
import { postForm } from './form';

const mergeParams = (...args) => {
  const params = args.reduce((acc, item) => {
    return {
      ...acc,
      ...(typeof item === 'string' ? getSearchParams(item) : item),
    };
  }, {});

  return stringify(params);
};

export const redirect = ({ payload: { url, ...redirectOptions } }) => {
  if (url && url.startsWith('http')) {
    const [pathname, search] = url.split('?');
    const uri = `${pathname}?${mergeParams(search, redirectOptions.search)}`;

    return externalRedirect(uri);
  } else {
    return internalRedirect(url)(redirectOptions);
  }
};

export const externalRedirect = (url: string) => {
  window.location.assign(url);
};

export const internalRedirect = (url: string) => (redirectOptions) => {
  //TODO to be implemented later
};

export const redirectWithFormSubmit = (url: string, params: any) => {
  postForm(url, params);
};
