import currency from './currency-formatter';

const data: any = { messages: {}, formats: {}, links: {} };

export const addLocaleData = (config) => {
  const { formats, links } = config;
  Object.assign(data, { formats, links });
};

export function getLink(id = '') {
  const link = data.links[id];

  return link || '';
}

export function formatCurrency(value, overrideConfig = {}) {
  const config = overrideConfig
    ? {
        ...data.formats?.currencyConfig,
        ...overrideConfig,
      }
    : data.formats?.currencyConfig;

  if (!config.symbol) {
    return undefined;
  }

  return currency(value, config);
}
