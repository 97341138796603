import { MessageDescriptor } from 'react-intl';
import { matchPath } from 'react-router-dom';
import { replace, push } from 'react-router-redux';

import * as paths from '../routes/pathConstants';
import { routes } from '../routes/routes';

export const navigate = (url, useReplace = null, state = {}) => {
  if (url && url.indexOf('http') === 0) {
    externalRedirect(url);
  } else {
    internalRedirect(url, useReplace, state);
  }
};

const externalRedirect = (url) => {
  window.location = url;
};

const internalRedirect = (url, useReplace, state) => {
  const routerAction = useReplace ? replace : push;
  routerAction(url, state);
};

const INSTANT_BANK_TRANSFER_TEXT = 'app.instant_bank_transfer_page.title';

type TPageTitleBase = Record<string, MessageDescriptor>;

type TPageTitle = Record<string, MessageDescriptor | TPageTitleBase>;

const pageTitles: TPageTitle = {
  [paths.INDEX]: {
    id: 'app.payment_selection_page.title',
  },
  [paths.PAYMENT_SELECTION]: {
    id: 'app.payment_selection_page.title',
  },
  [paths.PAYMENT_PENDING]: {
    id: 'app.payment_pending_page.title',
  },
  [paths.PAYMENT_RESULT]: {
    id: 'app.payment_result_page.title',
  },
  [paths.CARD]: {
    id: 'app.credit_card_page.title',
  },
  [paths.STORED_CARD]: {
    id: 'app.stored_card_page.title',
  },
  [paths.ABOUT]: {
    id: 'app.about_page.title',
  },
  [paths.SMS]: {
    id: 'app.sms_page.title',
  },
  [paths.INSTANT_BANK_TRANSFER]: {
    id: INSTANT_BANK_TRANSFER_TEXT,
  },
  [paths.BANK_TRANSFER_RESULT]: {
    id: 'app.bank_transfer_page.title',
  },
  [paths.OVER_THE_COUNTER_RESULT]: {
    id: 'app.over_the_counter_result_page.title',
  },
  [paths.MAINTENANCE_PAGE]: {
    id: 'app.maintenance_page.title',
  },
  [paths.UPI_TRANSFER]: {
    id: 'app.upi_page.title',
  },
  [paths.MBWAY_PAGE]: {
    id: 'app.mbway_page.title',
    defaultMessage: 'MB Way',
  },
  [paths.GENERIC]: {
    [paths.IBT]: {
      id: INSTANT_BANK_TRANSFER_TEXT,
    },
  },
};

export const getTitleAndSubtitle = (pathname: string): MessageDescriptor => {
  const route = routes.find((item) => matchPath(pathname, item));

  // In the case of the generic page, we want to get the specific keys for the page
  // and not a generic key. We do this by having a nested object with specific paths
  if (route && route.path === paths.GENERIC) {
    const titleKey = Object.keys(pageTitles[paths.GENERIC]).find((path) =>
      matchPath(pathname, {
        path,
        exact: true,
      }),
    );
    return pageTitles[paths.GENERIC][titleKey || ''] || {};
  }

  return pageTitles[route ? route.path : ''] || {};
};
