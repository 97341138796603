import { matchPath } from 'react-router-dom';

import { APP_ID } from '../routes/pathConstants';
import { routes } from '../routes/routes';

export const getCurrentPathMatch = (
  path = `/:${APP_ID}`,
  exact = false,
  strict = false,
) => matchPath(window.location.pathname, { path, exact, strict });

export const getCurrentPathParams = (...args) =>
  (getCurrentPathMatch(...args) || {}).params;

export const getCurrentRoute = (): any =>
  routes.find(({ path, exact, strict }: any) => {
    const match = getCurrentPathMatch(path, exact, strict);
    return !!match;
  });

export default { getCurrentPathMatch, getCurrentPathParams, getCurrentRoute };
