import { takeLatest, call, put } from 'redux-saga/effects';

import { shared as sharedApi } from '../../api';
import { setGlobal } from '../actions/error';
import { autoExecuteAction } from '../actions/generic';
// import { frontEndAttemptID as frontEndAttemptIdSelector } from "../../selectors/analytics";
import { paymentFlowFinishedAction } from '../actions/global';
import { loadableStart } from '../actions/loadable';
import { initializeAction, finalizeAction } from '../actions/v2';
import { trackPaymentMethodSelected } from './analytics/paymentMethodsAnalyticsService';
import { redirectViaFormSubmit, redirect } from './paymentFlowService';

export function* apiCallAction(payload) {
  const method = payload.httpMethod ? payload.httpMethod.toLowerCase() : 'get';
  const apiCall = yield call(sharedApi[method], payload.uri);
  const options: any = {
    version: 'V2',
    action: payload,
  };
  if (method === 'post' || method === 'put') {
    options.body = JSON.stringify(payload.params);
  }
  const response = yield call(apiCall, payload.params, options);

  return response.json;
}

export function* redirectFormAction(payload) {
  const flowFinishedAction = yield call(paymentFlowFinishedAction, {
    requestUrl: payload.uri,
    request: payload.params,
  });

  yield call(redirectViaFormSubmit, flowFinishedAction);
}

export function* initialize({ payload }) {
  try {
    yield call(trackPaymentMethodSelected, payload);
  } finally {
    yield executeAction(payload);
  }
}

export function* executeAction(payload) {
  if (!payload) {
    console.error('Invalid action provided');
    yield put(setGlobal('UNKNOWN'));
    yield put(finalizeAction());
    return;
  }

  switch (payload.type) {
    case 'REDIRECT_FORM': {
      yield call(redirectFormAction, payload);
      yield put(loadableStart());
      break;
    }

    case 'REDIRECT': {
      if (payload.uri) {
        yield call(redirect, { payload: { url: payload.uri } });

        if (!payload.uri.startsWith('http')) {
          yield put(finalizeAction());
          yield put(autoExecuteAction());
        }
      }
      break;
    }

    case 'EXECUTION':
    case 'API': {
      try {
        const response = yield call(apiCallAction, payload);
        payload = response.actions
          ? Object.values(response.actions)[0]
          : response.action;

        yield executeAction(payload);
      } catch (error) {
        console.error('Unexpected response from API', error);
      }

      yield put(finalizeAction());
      break;
    }

    default: {
      yield put(finalizeAction());
      break;
    }
  }
}

export default function* mainV2Saga() {
  yield takeLatest(initializeAction as any, initialize);
}
