import { ApiVersions } from '../../constants/ApiVersions';
import { ApiV2Response } from '../../types/ApiV2Response';
import { InterceptorType } from './InterceptorType';

export const previouslyCapturedInterceptor: InterceptorType = {
  predicate: (_, payload: ApiV2Response) =>
    payload &&
    payload.status === 'CAPTURED' &&
    payload.message === 'PREVIOUSLY_CAPTURED',
  sideEffect: (payload: ApiV2Response) => {
    switch (payload.version) {
      case ApiVersions.V1:
        break;
      case ApiVersions.V2:
      case ApiVersions['V2.1']:
      case ApiVersions['V2.2']: {
        if (payload.action.type === 'REDIRECT') {
          window.location.assign(payload.action.uri as string);
          return null;
        }

        break;
      }
      default: {
        break;
      }
    }

    return payload;
  },
};
