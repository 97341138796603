import { responseInterceptors } from './registeredInterceptors';

const getInterceptor = (res: any, payload: any, interceptors: any) =>
  interceptors.filter((i) => i.predicate(res, payload));

export const interceptResponse = (
  res: any,
  payload: any,
  url?: any,
  method?: any,
  options?: any,
) => {
  const interceptors = getInterceptor(res, payload, responseInterceptors);
  if (!interceptors) {
    return payload;
  }
  return interceptors.reduce((pl, i) => {
    // null is used as a terminator so no other interceptor will work
    // example: when a sideEffect redirects
    if (pl === null) {
      return null;
    }
    return i.sideEffect(
      pl,
      res && res.clone ? res.clone() : res,
      url,
      method,
      options,
    );
  }, payload);
};
