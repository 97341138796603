import React from 'react';

import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';

import { PageHeader } from '@olxui/core-v10/dist/core/PageHeader/PageHeader';
import { Headline2 } from '@olxui/core-v10/dist/core/Typography';
import { theme } from '@olxui/core/dist/theme/CoreTheme/coreTheme';

import { TAvailableIntegratorsConfig } from '../../constants/integrators';
import getAppId from '../../helpers/getAppId';
import { getTitleAndSubtitle } from '../../helpers/route';
import { useAppIdStyle } from '../../hooks';
import headerSelector from '../../selectors/header';
import BackButton from '../BackButton';
import Container from '../Container';
import Icon from '../Icon/Icon';
import { PaymentSecured } from '../PaymentSecured';

type Props = {
  children: React.ReactNode;
  location: { pathname: string };
  description: string;
  showHeaderControls: boolean;
  showHeader: boolean;
  showBackButton: boolean;
  paymentSecuredIcons: TAvailableIntegratorsConfig[];
};

export const Header = ({
  showBackButton,
  showHeader,
  location: { pathname } = {} as any,
  paymentSecuredIcons,
}: Props) => {
  const { formatMessage } = useIntl();
  const topClassStyle = useAppIdStyle('HeaderTop');
  const backClassStyle = useAppIdStyle('BackButton');
  const { id, defaultMessage } = getTitleAndSubtitle(pathname);

  if (!showHeader) {
    return null;
  }

  return (
    <>
      <HeaderTop className={topClassStyle}>
        <PageHeader.Left>
          <Container type="wide">
            <Logo
              app={getAppId()}
              id="logo"
              color="background-brand-secondary"
              size="72"
            />
            {showBackButton && (
              <BackButton
                className={backClassStyle}
                data-test="header-back-button"
              />
            )}
          </Container>
        </PageHeader.Left>
      </HeaderTop>

      {id && (
        <HeaderBottom type="wide">
          {id && (
            <StyledHeadline2 fontWeight="bold" data-test="page-title">
              {formatMessage({ id, defaultMessage })}
            </StyledHeadline2>
          )}

          {id === 'app.payment_selection_page.title' &&
            Boolean(paymentSecuredIcons.length) && (
              <PaymentSecured icons={paymentSecuredIcons} />
            )}
        </HeaderBottom>
      )}
    </>
  );
};

const Logo = styled(Icon)`
  display: none;

  @media ${theme.mediaQueries.smMin} {
    display: block;
  }
`;

const HeaderTop = styled(PageHeader)`
  @media ${theme.mediaQueries.smMin} {
    background-color: ${({ theme }: any) =>
      theme.colors['background-brand-primary']} !important;

    > div {
      height: 72px;
    }

    [data-test='header-back-button'] {
      display: none;
    }
  }
`;

const HeaderBottom = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 24px 0;

  [data-test='header-back-button'] {
    display: none;
  }

  @media ${theme.mediaQueries.smMin} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 40px auto;

    [data-test='header-back-button'] {
      display: block;
    }
  }
`;

const StyledHeadline2 = styled(Headline2)`
  overflow: hidden;
`;

export default compose(connect(headerSelector), pure)(Header as any) as any;
