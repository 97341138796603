import { ApiVersions, isOneOfApiVersions } from '../constants/ApiVersions';
import type { ApiCallOptions } from '../types/ApiCallOptions';
import { put, apiAction } from './shared';

type PaymentDetailsV1 = {
  id: string;
  paymentServiceProvider: string;
  hash: string;
  isTokenised: boolean;
  tokenId: string;
  subtype?: string;
  frontendAttemptId: string;
};

type PaymentDetailsV2 = {
  tokenId: string;
  encryptedCardData: string;
  storeData: boolean;
  subtype: string;
  cardData: string;
  cardtype: string;
  cardType: string;
  tokenized: boolean;
};

const doExecutePayment = put('/:paymentId/experience/result/card');

export const executePayment = (
  { id, ...body }: PaymentDetailsV1 & PaymentDetailsV2,
  options: ApiCallOptions = {} as any,
) => {
  if (
    isOneOfApiVersions([ApiVersions['V2.1'], ApiVersions['V2.2']])(
      options?.version,
    )
  ) {
    const additionalHeaders =
      body?.paymentServiceProvider?.toUpperCase() === 'PAYU'
        ? {
            'Browser-Width': screen.width.toString(),
            'Browser-Height': screen.height.toString(),
            'Browser-Color-Depth': screen.colorDepth.toString(),
            'Browser-Timezone':
              Intl.DateTimeFormat().resolvedOptions().timeZone,
          }
        : {};

    return apiAction(options?.actions?.submit, {
      body: {
        encryptedCardData: body.cardData || body.encryptedCardData,
        storeData: Boolean(body.storeData),
        subtype: body.cardType || body.cardtype || body.subtype,
        tokenId: body.tokenId,
        tokenized: Boolean(body.tokenized),
      },
      additionalHeaders: additionalHeaders,
    });
  }

  return doExecutePayment(
    { paymentId: id },
    {
      ...options,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  );
};

export default executePayment;
