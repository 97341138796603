import { theme } from '@olxui/core/dist/theme/CoreTheme/coreTheme';

import { AppTheme } from './types';

const colors = {
  primary: '#464B53',
  secondary: '#949494',
  tertiary: '#2C2C2C',
  grey: '#E0E0E0',
  white: '#ffffff',
};

export const storia: AppTheme = {
  ...theme,
  components: {
    BackButton: {
      '> svg': {
        color: colors.secondary,
      },
    },
    BasketDetails: {
      [`@media ${theme.mediaQueries.smMin}`]: {
        border: `1px solid ${colors.grey}`,
        'h6:last-child': {
          fontWeight: 'bold !important',
        },
      },
    },
    Body: {
      [`@media ${theme.mediaQueries.smMin}`]: {
        backgroundColor: colors['text-global-inverse'],
      },
    },
    ListItem: {
      [`@media ${theme.mediaQueries.smMin}`]: {
        border: `1px solid ${colors.grey} !important`,
      },
    },
    HeaderTop: {
      [`@media ${theme.mediaQueries.smMin}`]: {
        backgroundColor: `${colors.primary} !important`,
      },
      img: {
        'max-width': '10em',
        'margin-left': '1.5em',
        height: '65px',
      },
    },
  },
  staticConfig: {
    favIcon: '/icons/favicon-storia.ico',
  },
};
