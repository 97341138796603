export default async (appId = '', locale = 'en', type: string) => {
    try {
      try {
        const content = await import(`./${type}/${appId}`);
        return content.default || content;
      } catch (e) {
        const content = await import(`./${type}/${locale}`);
        return content.default || content;
      }
    } catch (error) {
      return {};
    }
};
