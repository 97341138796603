import React from 'react';

import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';

import * as pages from '../pages';
import { analyticsTrackPageAction } from '../redux/actions/analytics.ninja';
import * as paths from './pathConstants';

export const RouteWithSubRoutes = ({ path, exact, ...args }: any) => (
  <Route path={path} exact={exact} {...args} />
);

const withPageTracking = compose(
  connect(() => ({}), {
    trackPage: analyticsTrackPageAction,
  }),
  lifecycle({
    componentDidMount() {
      this.props.trackPage();
    },
  } as any),
);

const route = (path, component, exact = false) => ({
  path,
  component: compose(withPageTracking)(component),
  exact,
});

const exactRoute = (path, component) => route(path, component, true);

// IMPORTANT! Always add exact routes first
export const routes = [
  exactRoute(paths.INDEX, pages.PaymentSelectionPage),
  exactRoute(paths.PAYMENT_SELECTION, pages.PaymentSelectionPage),
  exactRoute(paths.PAYMENT_RESULT, pages.PaymentStatusContainer),
  exactRoute(paths.PAYMENT_PENDING, pages.PaymentPendingPage),
  exactRoute(paths.ERROR, pages.ErrorPage),
  exactRoute(paths.UPDATE_PAGE, pages.UpdatePageContainer),
  exactRoute(paths.PAYMENT_RESULT_MULTIBANCO, pages.PaymentResultMultibanco),
  exactRoute(paths.PAYMENT_RESULT_MBWAY, pages.PaymentResultMbway),
  exactRoute(paths.MBWAY_PAGE, pages.MbWayPage),
  route(paths.STORED_CARD, pages.PaymentSelectionPage),
  route(paths.STORED_CARD_PAGE, pages.PaymentSelectionPage),
  route(paths.CARD, pages.CreditCardContainer),
  route(paths.CARD_PAGE, pages.CardPage),
  route(paths.ABOUT, pages.AboutPage),
  route(paths.INSTANT_BANK_TRANSFER, pages.InstantBankTransferContainer),
  route(paths.SMS, pages.SmsPage),
  route(paths.MAINTENANCE_PAGE, pages.MaintenancePage),
  route(paths.OVER_THE_COUNTER, pages.PaymentSelectionPage),
  route(paths.GENERIC, pages.GenericPage),
];
