import { generateSecureRandomNumber } from './generateSecureRandomNumber';

const ninja: any = {};

const environment = process.env.REACT_APP_RUNTIME_ENVIRONMENT || 'production';
const hydraPath = 'plutus-web';
const hydraHost = process.env.REACT_APP_HYDRA_HOST;

let cookieDomain;

if (
  environment === 'production' &&
  window.configTracking &&
  window.configTracking.siteUrl
) {
  cookieDomain = window.configTracking.siteUrl.match(/^[\w\d-]+\.(.*)/);
  if (cookieDomain === null) {
    cookieDomain = window.configTracking.siteUrl;
  } else {
    cookieDomain = cookieDomain[1];
  }
} else {
  cookieDomain = window.location.host || null;
}

ninja.config = {
  environment,
  cookieDomain,
  hydraPath,
};

// Internal function - Get the url to call
ninja.getUrl = function (type, eventName, properties: Record<string, any>) {
  let url, key;

  const params = {
    host: window.location.host || null,
    hash: window.location.hash || null,
    referrer: document.referrer || null,
    sessionParams: ninja.getSessionParams(),
    user_platform: navigator.platform,
    browser_type: navigator.userAgent,
  };

  url = `https://${hydraHost}${ninja.config.hydraPath}?`;

  // Session
  url += `sl=${params.sessionParams.sessionLong}`;
  url += `&s=${params.sessionParams.session}`;
  url += `&cl=${params.sessionParams.sessionCountLong}`;
  url += `&c=${params.sessionParams.sessionCount}`;
  if (params.sessionParams.noCookie) {
    url += '&nc=1';
  }

  // trackPage // trackEvent
  url += `&eN=${eventName}`;
  url += `&tN=${type}`;

  // properties
  for (key in properties) {
    if (properties.hasOwnProperty(key) && properties[key] !== null) {
      const value = properties[key].toString();

      url += `&${ninja.eucex(key)}=${ninja.eucex(value)}`;
    }
  }

  // Current page
  url += `&cP=${ninja.eucex(ninja.getPageName())}`;

  // Non-important params

  // Host
  if (params.host) {
    url += `&host=${ninja.eucex(params.host)}`;
  }

  // Referrer
  if (params.referrer) {
    url += `&referer=${ninja.eucex(params.referrer)}`;
  }

  // Timestamp
  url += `&t=${new Date().getTime()}`;

  return url;
};

ninja.getSessionParams = function () {
  let date,
    now,
    session,
    sessionLong,
    sessionCount,
    sessionCountLong,
    sessionExpired,
    sessionValues,
    cookieValue,
    noCookie = false;

  let cookieName = 'onap_dev';
  let sessionExtra = null;

  try {
    if (navigator.cookieEnabled && typeof document.cookie !== 'undefined') {
      date = new Date();
      now = Math.round(date.getTime() / 1000);

      if (ninja.config.environment === 'production') {
        cookieName = 'onap';
      }

      sessionValues = ninja.getCookie(
        cookieName,
        /^([a-z0-9]+)-([0-9]{1,5})-([a-z0-9]+)-([0-9]{1,5})-([0-9]{1,10})$/,
      );

      if (sessionValues?.length) {
        sessionLong = sessionValues[1];
        sessionCountLong = parseInt(sessionValues[2], 10);
        session = sessionValues[3];
        sessionCount = parseInt(sessionValues[4], 10);
        sessionExpired = sessionValues[5];
        sessionExtra = sessionValues[6];
        sessionCount += 1;

        if (sessionExpired - now <= 0) {
          sessionCountLong += 1;
          session = ninja.generateSession();
          sessionCount = 1;
        }
      } else {
        sessionLong = ninja.generateSession();
        sessionCountLong = 1;
        session = sessionLong;
        sessionCount = 1;
        sessionExtra = null;
      }

      sessionExpired = now + 1800;
      cookieValue = `${sessionLong}-${sessionCountLong}-${session}-${sessionCount}-${sessionExpired}`;

      if (sessionExtra) {
        cookieValue = `${cookieValue}-${sessionExtra}`;
      }

      cookieValue = cookieValue.replace(/[^\w\-=]/g, '');
      ninja.setCookie(cookieName, cookieValue, 360 * 24 * 60);
    } else {
      sessionLong = ninja.generateSession();
      sessionCountLong = 1;
      session = sessionLong;
      sessionCount = 1;
      noCookie = true;
    }
  } catch (ignore) {
    sessionLong = ninja.generateSession();
    sessionCountLong = 1;
    session = sessionLong;
    sessionCount = 1;
    noCookie = true;
  }

  const sessionParams: any = {
    sessionLong,
    session,
    sessionCountLong,
    sessionCount,
  };

  if (noCookie) {
    sessionParams.noCookie = noCookie;
  }

  return sessionParams;
};

ninja.getCookie = function (cName, regExp) {
  const name = `${cName}=`;

  let cA: any = '',
    cI,
    cookie,
    cValue,
    rValue = null;

  try {
    cA = document.cookie.split(';');
  } catch (ignore) {
    // empty
  }

  for (cI = 0; cI < cA.length; cI += 1) {
    cookie = cA[cI];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.search(name) === 0) {
      cValue = cookie.substring(name.length, cookie.length);
      if (regExp) {
        rValue = cValue.match(regExp);
      } else {
        rValue = cValue;
      }
      break;
    }
  }
  return rValue;
};

ninja.generateSession = function () {
  const randomValue = generateSecureRandomNumber();

  return `${Number(new Date().getTime()).toString(16)}x${Number(
    Math.floor(randomValue * 2147483647 + 1),
  ).toString(16)}`;
};

ninja.setCookie = function (cName, cValue, eMinutes, cPath, cDomain) {
  const cD = new Date();
  let cExpires;

  try {
    cD.setTime(cD.getTime() + eMinutes * 60 * 1000);
    cExpires = `;expires=${cD.toUTCString()}`;
    cDomain = `;domain=.${cDomain || ninja.config.cookieDomain}`;
    cPath = `;path=${cPath || '/'}`;

    document.cookie = `${cName}=${cValue}${cExpires}${cDomain}${cPath}`;
  } catch (err) {
    // empty
  }
};

// Encode value
ninja.eucex = function (value) {
  let encode;

  try {
    encode = encodeURIComponent(decodeURIComponent(value));
  } catch (ignore) {
    encode = '';
  }

  return encode;
};

// Internal function - Make the call using img
ninja.trackWithImage = (url, ImageConstructor = window.Image) =>
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), 500);
    url += '&js=1';
    const hydraImage = new ImageConstructor();
    hydraImage.onload = () => {
      resolve();
      hydraImage.onload = null;
    };
    hydraImage.onerror = () => {
      resolve();
      hydraImage.onerror = null;
    };
    hydraImage.src = url;
  });

// Internal function - Translate pageName
ninja.getPageName = function () {
  let pathname = window.location.pathname;
  if (pathname.charAt(0) === '/' && pathname.length > 1) {
    pathname = pathname.slice(1);
  }
  if (pathname.charAt(pathname.length - 1) === '/' && pathname.length > 1) {
    pathname = pathname.slice(0, pathname.length - 1);
  }
  if (pathname.search(/\./) > 0) {
    pathname = pathname.replace(/(\.[a-z]+$)/, '');
  }
  if (pathname === '/' || pathname === '') {
    pathname = 'home';
  }
  return pathname;
};

export default ninja;
