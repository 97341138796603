import { get, orchestrationHost, simpleFetch } from './shared';

export const initialize = get(
  `/:paymentId/experience/initialization/smsPayment`,
);

export const fortumoServiceInitialize = (paymentId: string) =>
  simpleFetch({
    url: `${orchestrationHost()}/olx_pl/sms/fortumo/initialize/${paymentId}`,
    method: 'GET',
    options: { version: 'V2' },
  });
