import { useEffect } from 'react';

import useAxios from 'axios-hooks';

import {
  EPaymentMethods,
  EPaymentProviders,
} from '../../constants/integrators';
import { useErrorHandler } from '../../contexts';
import { useAppContext } from '../../contexts/AppContext/AppContext';
import { useLoaderContext } from '../../contexts/LoaderContext/LoaderContext';
import { IInitializeCommonResponse } from './types';

export const usePaymentInitializeRequest = <Request = any, Response = any>(
  methodType: EPaymentMethods,
  pspName: EPaymentProviders,
) => {
  const {
    parsedParams: {
      init: { integrator, paymentId },
    },
  } = useAppContext();

  const { showLoading, hideLoading } = useLoaderContext();

  const { handleApiErrorResponse } = useErrorHandler();

  const [{ data, loading, error }, initRequest] = useAxios<
    IInitializeCommonResponse & Response,
    Request
  >(
    `/${integrator.toLowerCase()}/${methodType}/${pspName.toLowerCase()}/initialize/${paymentId}`,
    {
      manual: true,
    },
  );

  const handleInitializeRequest = async () => {
    try {
      await initRequest();
    } catch (responseError) {
      handleApiErrorResponse(responseError);
    }
  };

  useEffect(() => {
    loading ? showLoading() : hideLoading();
  }, [loading]);

  return { data, error, handleInitializeRequest };
};
