import React from 'react';

import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

import DotsLoader from '@olxui/core-v10/dist/core/Loading/DotsLoader';
import { Paragraph4 } from '@olxui/core-v10/dist/core/Typography';

import Modal from '../Modal';

export const ProgressLoader = ({ isOpen, children }: any) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon={false}
      title={formatMessage({ id: 'app.credit_card_page.confirm.title' })}
    >
      <ModalBody>
        <DotsLoader />
        {children || (
          <Paragraph4>
            {formatMessage({ id: 'app.credit_card_page.confirm.paragraph' })}
          </Paragraph4>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ProgressLoader;

const ModalBody = styled.div`
  text-align: center;

  > div {
    padding: 32px 16px;
  }
`;
