import { createAction } from 'redux-actions';

export const genInitializeStart = createAction('GEN_INITIALIZE_START');
export const genInitializeSuccess = createAction('GEN_INITIALIZE_SUCCESS');
export const genInitializeFailure = createAction('GEN_INITIALIZE_FAILURE');
export const genActionAPIStart = createAction('GEN_ACTION_API_START');
export const genActionAPISuccess = createAction('GEN_ACTION_API_SUCCESS');
export const genActionAPIFailure = createAction('GEN_ACTION_API_FAILURE');
export const genClear = createAction('GEN_CLEAR');
export const genInitByConvention = createAction('INIT_BY_CONVENTION');

export const onChangeFilter = createAction('GEN_CHANGE_FILTER');
export const selectOfflineBankAction = createAction('GEN_OFFLINE_BANK_SELECT');
export const closeOfflineBankNotice = createAction(
  'GEN_OFFLINE_BANK_CLOSE_NOTICE',
);

export const autoExecuteAction = createAction(
  '[Get Methods] Method auto execution',
);
