import { useEffect, useState } from 'react';

export const useExternalScript = (url: string) => {
  const [state, setState] = useState(url ? 'loading' : 'idle');

  useEffect(() => {
    if (!url) {
      setState('idle');
      return;
    }

    let script = document.querySelector<HTMLScriptElement>(
      `script[src="${url}"]`,
    );

    const handleScript = (e) => {
      setState(e.type === 'load' ? 'ready' : 'error');
    };

    if (!script) {
      script = document.createElement('script');
      script.type = 'application/javascript';
      script.src = url;

      if (document.getElementsByTagName('head').length) {
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    }

    script?.addEventListener('load', handleScript);
    script?.addEventListener('error', handleScript);

    return () => {
      script?.removeEventListener('load', handleScript);
      script?.removeEventListener('error', handleScript);
    };
  }, [url]);

  return state;
};
