import {
  flow,
  getOr,
  has,
  mapValues,
  merge,
  reduce as cappedReduce,
  noop,
} from 'lodash/fp';
import qs from 'query-string';

import getAppId from '../helpers/getAppId';
import getAuthToken from '../helpers/getAuthToken';
import * as nr from '../helpers/new-relic-wrapper';
import {
  previewHeaders as getPreviewHeaders,
  testCase as getTestCase,
} from '../selectors/previewMode';
import type { ApiCallOptions } from '../types/ApiCallOptions';
import type { ApiV2Action } from '../types/ApiV2Action';
import type { FetchOptions } from '../types/FetchOptions';
import { generateIdempotency, orchestrationUrls } from './configureBaseRequest';
import { interceptResponse } from './interceptors';
import {
  authenticationInterceptor,
  browserInfoInterceptor,
  idempotencyInterceptor,
} from './requestInterceptors';

const reduce = (cappedReduce as any).convert({ cap: false });

const fillUrlTemplate = (urlTemplate: string, params?: RequestParams): any => {
  const safeParams = mapValues(encodeURIComponent)(params);
  return flow(
    reduce(
      (url, value, name) => url.replace(new RegExp(`:${name}`, 'g'), value),
      urlTemplate,
    ),
    (url) => {
      if (has('queryString', params)) {
        return url + '?' + qs.stringify(params?.queryString);
      }

      return url;
    },
  )(safeParams);
};

const shouldMockOrchestration = () =>
  process.env.REACT_APP_RUNTIME_ENVIRONMENT === 'development' &&
  /[?&]mockOrchestration=true(&|$)/.test(window.location.search);

export const orchestrationHost = (appId = getAppId()) => {
  if (shouldMockOrchestration()) {
    return process.env.REACT_APP_MOCK_ORCHESTRATION_URL;
  }

  return (
    orchestrationUrls[appId] ??
    orchestrationUrls.default ??
    process.env.REACT_APP_PL_ORCHESTRATION_URL ??
    ''
  );
};

type RequestParams = {
  queryString?: any;
  [key: string]: any;
};

const v2Strategy = (_, __, { actions }: ApiCallOptions) => {
  try {
    return encodeURI(fillUrlTemplate(actions?.submit.uri));
  } catch (error) {
    throw new Error(
      'Incorrect definition of a V2.1/V2.2 API call, provide `actions.submit.uri`.',
    );
  }
};

const createApiUriStrategies = {
  V1: (urlTemplate: string, params: RequestParams) =>
    encodeURI(
      `${orchestrationHost()}/payments${fillUrlTemplate(urlTemplate, params)}`,
    ),
  V2: (urlTemplate: string, params: RequestParams, options: ApiCallOptions) => {
    if (options.action && (options.action.uri || options.action.url)) {
      return encodeURI(
        fillUrlTemplate(options.action.uri || options.action.url),
      );
    }

    throw new Error(
      'Incorrect definition of a V2 API call, provide options.action.uri.',
    );
  },
  'V2.1': v2Strategy,
  'V2.2': v2Strategy,
};

const createApiUri = (
  urlTemplate: string,
  params: RequestParams,
  options: FetchOptions,
) => {
  const version = getOr('V1', 'version', options);
  const strategy = createApiUriStrategies[version];

  return strategy(urlTemplate, params, options);
};

const injectAppIdHeader = merge({ headers: { 'Plutus-App-Id': getAppId() } });

const injectAuthToken = (req) => {
  const token = getAuthToken();
  return authenticationInterceptor(req as any, token as any);
};

const injectPreviewHeaders = (req) => {
  const previewHeaders = getPreviewHeaders(null);
  return previewHeaders
    ? merge({ headers: { 'X-Plutus-Features': previewHeaders } }, req)
    : req;
};

export const injectTestCaseHeaders = (req) => {
  const testCaseHeaders = getTestCase(null);
  return testCaseHeaders
    ? merge({ headers: { 'X-Plutus-TestCase': testCaseHeaders } }, req)
    : req;
};

const injectContentTypeHeader = (req) => {
  const { method, action = {} } = req;
  if (action.contentType && (method === 'POST' || method === 'PUT')) {
    return merge({ headers: { 'Content-Type': action.contentType } }, req);
  }
  return req;
};

const injectBrowserInfo = (req) => {
  return browserInfoInterceptor(req);
};

const injectIdempotencyHeader = (req) => {
  return idempotencyInterceptor(req, req.idempotency);
};

export const decorateFetchOptions = flow(
  injectAppIdHeader,
  injectAuthToken,
  injectPreviewHeaders,
  injectTestCaseHeaders,
  injectContentTypeHeader,
  injectIdempotencyHeader,
  injectBrowserInfo,
  injectIdempotencyHeader,
  // More decorators here...
);

const determineApiVersion = (apiCallOptions: ApiCallOptions) =>
  apiCallOptions.version || (apiCallOptions.action ? 'V2' : 'V1');

const getBackendEndpointToReplace = {
  production: 'https://api.plutus.olx.tools',
  staging: 'https://api-stg.plutus.olx.tools',
};

export const simpleFetch = async ({
  url,
  method,
  options,
}: {
  url: string;
  method?: string;
  options?: any;
}) => {
  const idempotency = generateIdempotency();

  const opts = { ...options };
  if (method) {
    opts.method = method;
  }
  opts.credentials = 'include';

  let result: Response = {} as any;
  let payload = null;
  let decoratedOptions: any = null;
  let logsOptions: any = {};
  try {
    // TODO: Remove this hack when backend is updated providing the non Akamai URLs
    if (
      url.startsWith(
        getBackendEndpointToReplace[
          process.env.REACT_APP_RUNTIME_ENVIRONMENT as string
        ],
      )
    ) {
      url = url.replace(
        getBackendEndpointToReplace[
          process.env.REACT_APP_RUNTIME_ENVIRONMENT as string
        ],
        orchestrationHost(),
      );
    }

    decoratedOptions = decorateFetchOptions({ ...opts, url, idempotency });

    delete decoratedOptions.url;
    delete decoratedOptions.idempotency;

    const { Authorization: _, ...rest } = decoratedOptions.headers;
    logsOptions = rest;

    nr.addPageAction(nr.PAGE_ACTION_NAMES.API_START, {
      requestUrl: url,
      options: { ...decoratedOptions, headers: { ...logsOptions } },
    });

    result = await fetch(url, decoratedOptions);
    payload = await result.clone().json().catch(noop);
    payload = interceptResponse(result, payload, url, method, decoratedOptions);
  } catch (err: any) {
    nr.addPageAction(nr.PAGE_ACTION_NAMES.API, {
      status: nr.API_STATUS.FAIL,
      ...err,
      requestUrl: url,
      options: { ...decoratedOptions, headers: { ...logsOptions } },
      payload,
    });

    return {
      result: { ...result, url, ok: false, statusText: err.message },
      json: payload,
    };
  }
  return { result, json: payload };
};

// TODO fix flow
export const apiCall =
  (defaultMethod = 'GET') =>
  (urlTemplate: string) =>
    async function apiCall(
      params: RequestParams,
      apiCallOptions: ApiCallOptions = {} as any,
    ): Promise<any> {
      const method = apiCallOptions.httpMethod || defaultMethod;
      const url = createApiUri(urlTemplate, params, {
        ...apiCallOptions,
        version: determineApiVersion(apiCallOptions) as any,
      });

      return await simpleFetch({
        url,
        method,
        options: apiCallOptions,
      });
    };

export const get = apiCall();
export const _delete = apiCall('DELETE');
export const put = apiCall('PUT');
export const post = apiCall('POST');

export const behaviorForTesting = {
  fillUrlTemplate,
};

export const apiAction = (
  { uri, params, httpMethod, payload, contentType }: ApiV2Action,
  { body, additionalHeaders = {}, ...rest }: any = {},
) =>
  simpleFetch({
    method: httpMethod,
    options: {
      body: JSON.stringify({ ...params, ...payload, ...body }),
      headers: merge(additionalHeaders, {
        Accept: 'application/json',
        'Content-Type': contentType || 'application/json',
      }),
      ...rest,
    },
    url: uri as any,
  });
