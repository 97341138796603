import { theme } from '@olxui/core/dist/theme/CoreTheme/coreTheme';

import { AppTheme } from './types';

const colors = {
  primary: '#ffffff',
  grey: '#E0E0E0',
  white: '#ffffff',
};

export const otomoto: AppTheme = {
  ...theme,
  components: {
    BackButton: {
      '> svg': {
        color: colors.grey,
      },
    },
    BasketDetails: {
      [`@media ${theme.mediaQueries.smMin}`]: {
        border: `1px solid ${colors.grey}`,
        'h6:last-child': {
          fontWeight: 'bold !important',
        },
      },
    },
    Body: {
      [`@media ${theme.mediaQueries.smMin}`]: {
        backgroundColor: colors.white,
      },
    },
    ListItem: {
      [`@media ${theme.mediaQueries.smMin}`]: {
        border: `1px solid ${colors.grey} !important`,
      },
    },
    HeaderTop: {
      [`@media ${theme.mediaQueries.smMin}`]: {
        backgroundColor: ` ${colors.primary} !important`,
        boxShadow: 'rgb(20 35 85 / 15%) 0px 2px 6px 0;',
      },
      img: {
        maxWidth: '11.5em',
      },
    },
  },
  staticConfig: {
    favIcon: '/icons/favicon-otomoto.ico',
    paymentPendingAlertType: 'action',
    paymentPendingAlertIcon: 'pending',
  },
};
