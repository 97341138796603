import {
  flow,
  get,
  has,
  last,
  isUndefined,
  mapKeys,
  mapValues,
  split,
  reduce as cappedReduce,
} from 'lodash/fp';
import { put, select } from 'redux-saga/effects';

import { APP_ID } from '../../../routes/pathConstants';
import globalSelector from '../../../selectors/global';
import type {
  EventTrackingArgs,
  PageTrackingArgs,
  Tracker,
} from '../../../types/Tracker';
import {
  analyticsTrackPageSuccessAction,
  analyticsTrackEventSuccessAction,
  analyticsTrackPageErrorAction,
  analyticsTrackEventErrorAction,
} from '../../actions/analytics.ninja';
import { pageNames, methodTypesPerPath } from './constants';
import newRelicTrackingStrategy from './newRelicTrackingStrategy';
import ninjaTrackingStrategy from './ninjaTrackingStrategy';
import { scrubProperties } from './propertiesScrubHelper';

const reduce = (cappedReduce as any).convert({ cap: false });

const trackingStrategies = [ninjaTrackingStrategy, newRelicTrackingStrategy];

const stripAppId = flow(split('/'), last);

const getRoutePath = (url: string): string => `/:${APP_ID}/${url}`;

const getTrackPageEventName = flow(stripAppId, (url) =>
  `${pageNames[getRoutePath(url as string)] || url}_Page_Viewed`.toLowerCase(),
);

const getTrackPageEventParams = flow(stripAppId, (url) => {
  const result: any = {};
  const routePath = getRoutePath(url as string);
  if (pageNames[routePath] === 'method') {
    result.methodType = methodTypesPerPath[routePath];
  }

  return result;
});

/* TODO - Pageview

   - Session ID
   - Browser type ( Sending user agent )
*/

const pageVariables = {
  paymentId: 'Encrypted_Payment_Id',
  frontEndAttemptID: 'Front_End_Attempt_Id',
  platform: 'Device_Type',
  integrator: 'Integrator',
  error: 'Error_Message_Type',
  methodType: 'Method_Type',
};

const eventVariables = {
  paymentId: 'Encrypted_Payment_Id',
  platform: 'Device_Type',
  integrator: 'Integrator',
  incorrectFields: 'incorrect-fields',
  checked: 'Checkbox_Action',
  linkType: 'Selected_Link_Type',
  fieldName: 'Field_Name',
  field_is_valid: 'field_is_valid',
  field_length: 'field_length',
  frontEndAttemptID: 'Front_End_Attempt_Id',
  encryptedAttemptId: 'Back_End_Attempt_Id',
  bankType: 'Selected_Bank_Type',
  cardType: 'Card_Type',
  isEmpty: 'Field_Empty',
  formValid: 'Form_Valid',
  methodType: 'Method_Type',
  actionType: 'Action_Type',
  paymentResult: 'Payment_Result',
  redirection3ds: 'Redirection_3DS',
  applied3DSecure: '3ds_applied',
  searchFilter: 'search_bar',
  searchQuery: 'search_query',
};

const getProperties = (variables) => (data) =>
  reduce((result, value, key) => {
    if (!isUndefined(value) && value !== null && has(key, variables)) {
      result[variables[key]] = value;
    }

    return result;
  }, {})(data);

const getPageProperties = getProperties(pageVariables);
const getEventProperties = getProperties(eventVariables);

// Value normalization logic.
// Current schema is to have everything lowercase.
const normalize = (x) => x.toString().toLowerCase();

const normalizeValues = mapValues(normalize);
const normalizeKeys = mapKeys(normalize);

const normalizeObject = flow(normalizeKeys, normalizeValues);

function* trackPage({ url, error }: PageTrackingArgs) {
  const eventName = getTrackPageEventName(url);
  const frontEndAttemptID = yield select(get('analytics.frontEndAttemptID'));
  const globalData = yield select(globalSelector);
  const properties = scrubProperties(
    normalizeObject(
      getPageProperties({
        ...getTrackPageEventParams(url),
        ...globalData,
        error,
        frontEndAttemptID,
      }),
    ),
    eventName,
  );

  try {
    for (const tracker of trackingStrategies) {
      if ('production' !== process.env.REACT_APP_RUNTIME_ENVIRONMENT) return;

      yield tracker.trackPage({ eventName, params: properties });
    }

    yield put(analyticsTrackPageSuccessAction({ eventName, properties }));
  } catch (err) {
    yield put(analyticsTrackPageErrorAction({ eventName, error: err }));
    throw err;
  }
}

function* trackEvent(args: EventTrackingArgs) {
  const { params } = args;
  const eventName = args.eventName.toLowerCase();
  const globalData = yield select(globalSelector);
  const frontEndAttemptID = yield select(get('analytics.frontEndAttemptID'));

  const properties = scrubProperties(
    normalizeObject(
      getEventProperties({
        ...globalData,
        ...params,
        frontEndAttemptID,
      }),
    ),
    eventName,
  );

  try {
    for (const trackingStrategy of trackingStrategies) {
      if ('production' !== process.env.REACT_APP_RUNTIME_ENVIRONMENT) return;

      yield trackingStrategy.trackEvent({ eventName, params: properties });
    }

    yield put(analyticsTrackEventSuccessAction({ eventName, properties }));
  } catch (err) {
    yield put(analyticsTrackEventErrorAction({ eventName, error: err }));
    throw err;
  }
}

const compositeTracker: Tracker = {
  trackPage,
  trackEvent,
};

export default compositeTracker;
