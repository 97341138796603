export const enum EIntegrator {
  OTODOM_PL = 'OTODOM_PL',
  OTOMOTO_PL = 'OTOMOTO_PL',
  UA = 'OLX_UA',
  PL = 'OLX_PL',
  RO = 'OLX_RO',
}

export const enum EPaymentProviders {
  PAYU = 'PAYU',
  ADYEN = 'ADYEN',
  PORTMONE = 'PORTMONE',
  TRANZZO = 'TRANZZO',
}

export const enum EPaymentMethods {
  MBWAY = 'mbway',
  CARD = 'card',
}

export type TAvailableIntegratorsConfig = { pspIconUri: string; psp: string };
