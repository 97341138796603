import styled from '@emotion/styled';
import ReactDOM from 'react-dom';

const id = 'bottom-overlay-root';

export default ({ children }) => {
  const domElement = document.getElementById(id);
  return domElement ? ReactDOM.createPortal(children, domElement) : null;
};

export const PortalNode = styled('div')`
  z-index: ${({ theme }: any) => theme.zIndices.popover};
`;

PortalNode.defaultProps = { id };
