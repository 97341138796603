import cardValidator from 'card-validator';

import getAppId from './getAppId';

const { creditCardType } = cardValidator;

const CustomCardTypes = {
  generic: [
    {
      niceType: 'Unknown',
      type: 'unknown',
      patterns: [],
      gaps: [4, 8, 12],
      lengths: [16],
      code: {
        name: 'CVV',
        size: 3,
      },
    },
    {
      niceType: 'American Express',
      // The type used internally does not match the one provided
      // by the "card-validator" lib.
      // We are adding the type "amex" instead of creditCardType.types.AMERICAN_EXPRESS,
      // to allow the correct usage of the code as CID width 4 digits when the user validates
      // the tokenized card.
      // It may create less noise and may be clear if we create an internal mapping of
      // "our used card types"/"lib card types".
      type: 'amex',
      patterns: [],
      gaps: [4, 10],
      lengths: [15],
      code: {
        name: 'CID',
        size: 4,
      },
    },
  ],
  byAppId: {
    in: [
      {
        niceType: 'Rupay',
        type: 'rupay',
        patterns: [60, 6521, 6522],
        gaps: [4, 8, 12],
        lengths: [16],
        code: {
          name: 'CVV',
          size: 3,
        },
      },
      {
        niceType: 'Maestro',
        type: 'old_maestro',
        patterns: [6220180],
        gaps: [4, 7, 11, 15],
        lengths: [19],
        code: {
          name: 'CVV',
          size: 3,
        },
      },
    ],
    ua: [
      {
        niceType: 'Prostir',
        type: 'prostir',
        patterns: [9804],
        gaps: [4, 8, 12],
        lengths: [16],
        code: {
          name: 'CVV',
          size: 3,
        },
      },
    ],
    tr: [
      {
        niceType: 'Troy',
        type: 'troy',
        patterns: [9792],
        gaps: [4, 8, 12],
        lengths: [16],
        code: {
          name: 'CVV',
          size: 3,
        },
      },
    ],
  },
};

creditCardType.updateCard(creditCardType.types.VISA, {
  lengths: [13, 16, 18, 19],
});

const genericTypes = CustomCardTypes.generic;
const specificTypes = CustomCardTypes.byAppId[getAppId()] || [];
[...genericTypes, ...specificTypes].forEach((cardType) =>
  creditCardType.addCard(cardType),
);

export default cardValidator.creditCardType;
