import { takeLatest, put, all, call } from 'redux-saga/effects';

import * as GooglePayApi from '../../api/googlePay';
import { setGlobal as setError } from '../actions/error';
import {
  googlePayTrackDisplayedAction,
  googlePayInitializeAction,
  googlePayExecuteAction,
  googlePayCancelAction,
  googlePaySetExecuteDataAction,
  googlePaySetCancelDataAction,
} from '../actions/googlePay';
import { loadableFinish, loadableStart } from '../actions/loadable';
import * as GooglePayAnalyticsService from './analytics/googlePayAnalyticsService';
import { redirect } from './paymentFlowService';

export function* trackGooglePayDisplayed() {
  yield call(GooglePayAnalyticsService.trackGooglePayDisplayed);
}

export function* initialize(action) {
  try {
    yield put(loadableStart());
    const response = yield call(GooglePayApi.initialize, {
      url: action.payload.url,
      method: action.payload.method,
    });
    yield put(loadableFinish());

    if (response.result.ok) {
      const executeActionData = response.json?.actions?.submit;
      const attemptId = executeActionData.uri.split('/').pop();
      yield call(GooglePayAnalyticsService.trackGooglePaySelected, attemptId);

      if (executeActionData) {
        yield put(googlePaySetExecuteDataAction(executeActionData));
      }

      const cancelActionData = response.json?.actions?.execute;
      if (cancelActionData) {
        yield put(googlePaySetCancelDataAction(cancelActionData));
      }
    } else {
      throw new Error('Failed to call initialize');
    }
  } catch (e) {
    yield put(loadableFinish());
    window.newrelic.noticeError(e, {
      methodType: 'GOOGLE_PAY',
      message: 'Failed to initialize payment',
    });
    yield put(setError('generic'));
  }
}

export function* execute(action) {
  try {
    yield put(loadableStart());
    const response = yield call(GooglePayApi.execute, {
      url: action.payload.url,
      method: action.payload.method,
      token: action.payload.token,
    });
    yield put(loadableFinish());

    let experienceCompletedUrl: any;

    if (response.result.ok) {
      switch (response.json.status) {
        case 'REJECTED':
          yield put(setError('generic'));
          break;

        case 'CAPTURED':
          experienceCompletedUrl = response.json.actions.submit.uri;
          yield call(redirect, { payload: { url: experienceCompletedUrl } });
          break;

        default:
          throw new Error(
            `Unexpected payload status ${response.status} for Google Pay flow`,
          );
      }
    } else {
      throw new Error('Failed to call execute');
    }
  } catch (e) {
    yield put(loadableFinish());
    window.newrelic.noticeError(e, {
      methodType: 'GOOGLE_PAY',
      message: 'Failed to execute payment',
    });
    yield put(setError('generic'));
  }
}

export function* cancel(action) {
  try {
    yield put(loadableStart());
    const response = yield call(GooglePayApi.cancel, {
      url: action.payload.url,
      method: action.payload.method,
    });
    yield put(loadableFinish());

    if (!response.result.ok) {
      throw new Error('Non successful response from cancel API');
    }
  } catch (e) {
    yield put(loadableFinish());
    window.newrelic.noticeError(e, {
      methodType: 'GOOGLE_PAY',
      message: 'Failed to cancel payment',
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(googlePayTrackDisplayedAction, trackGooglePayDisplayed),
    takeLatest(googlePayInitializeAction, initialize),
    takeLatest(googlePayExecuteAction, execute),
    takeLatest(googlePayCancelAction, cancel),
  ]);
}
