import { keyBy } from 'lodash';
import { matchPath } from 'react-router';
import type { Route } from 'react-router';
import { LOCATION_CHANGE, push } from 'react-router-redux';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import getAppId from '../../helpers/getAppId';
import getSearchParams from '../../helpers/getSearchParams';
import populateParams from '../../helpers/populateParams';
import * as paths from '../../routes/pathConstants';
import { routes } from '../../routes/routes';
import { ccPaymentErrorAction } from '../actions/creditCard';
import { setGlobal } from '../actions/error';
import { cancelExperienceAction } from '../actions/global';
import { psPollForStatusAction } from '../actions/paymentStatus';
import {
  redirectAction,
  redirectToPaymentMethodsAction,
} from '../actions/routing';
import * as paymentFlowService from './paymentFlowService';

export type PaymentFlowType = 'CARD' | 'INSTANT_BANK_TRANSFER' | 'SMS';

type MappedRoutesType = {
  [key: string]: Route;
};

const mappedRoutes: MappedRoutesType = keyBy(routes, (x) => x.path) as any;

type ActionParamInstruction = {
  redirectToRoute?: Route;
  errorMsgKey?: string;
  cardMsgKey?: string;
  dispatchAction?: any;
};

type ActionParamInstructions = {
  [key: string]: ActionParamInstruction;
};

const actionParamInstructionsMap: ActionParamInstructions = {
  TRY_OTHER_METHOD: {
    redirectToRoute: mappedRoutes[paths.PAYMENT_SELECTION],
    errorMsgKey: 'choose_another_method',
  },
  RETRY_METHOD: {
    redirectToRoute: mappedRoutes[paths.PAYMENT_SELECTION],
    errorMsgKey: 'retry_method',
  },
  RE_ENTER_CARD_DATA: {
    redirectToRoute: mappedRoutes[paths.CARD],
    // This is a hack.
    // TODO: Refactor error behavior to have a single Error component (on all pages)
    cardMsgKey: 're_enter_card_data',
  },
  POLL: {
    redirectToRoute: mappedRoutes[paths.PAYMENT_RESULT],
    dispatchAction: psPollForStatusAction,
  },
  UNKNOWN: {
    redirectToRoute: mappedRoutes[paths.PAYMENT_SELECTION],
    errorMsgKey: 'generic',
  },
  REJECTED_BY_ISSUER: {
    redirectToRoute: mappedRoutes[paths.PAYMENT_SELECTION],
    errorMsgKey: 'payment_rejected_issuer',
  }
};

type IsRedirectNeededType = (actionParam: string, pathname: string) => boolean;
const isRedirectNeededForActionParam: IsRedirectNeededType = (
  actionParam = '',
  pathname,
) => {
  const userMessageMap = actionParamInstructionsMap[actionParam];
  return (
    userMessageMap &&
    !matchPath(pathname, userMessageMap.redirectToRoute as any)
  );
};

type LocationChangePayload = {
  payload: { search: string; pathname: string };
};

function* doActionParamInstruction({
  payload: { search, pathname } = {} as any,
}: LocationChangePayload): Generator<any, void, any> {
  const searchParams = getSearchParams(search);
  const { action: actionParam } = searchParams;
  if (!actionParam) {
    return;
  }
  const { redirectToRoute, errorMsgKey, cardMsgKey, dispatchAction } =
    actionParamInstructionsMap[actionParam as string];
  if (
    isRedirectNeededForActionParam(actionParam as string, pathname) &&
    !!redirectToRoute
  ) {
    const appId = getAppId();
    const newPath = populateParams((redirectToRoute as any).path, { appId });
    yield put(
      push({
        pathname: newPath,
        search,
      }),
    );
  }

  if (errorMsgKey) {
    yield put(setGlobal(errorMsgKey));
  }
  if (cardMsgKey) {
    yield put(ccPaymentErrorAction(undefined, { message: cardMsgKey }));
  }
  if (dispatchAction) {
    yield put(dispatchAction(searchParams));
  }
}

function* handleLocationChange(payload: LocationChangePayload) {
  yield doActionParamInstruction(payload);
}

const makeMainSaga = ({ behavior }) =>
  function* paymentFlowSaga() {
    yield takeLatest(cancelExperienceAction, behavior.cancelExperience);
    yield takeLatest(redirectAction, behavior.redirect);
    yield takeLatest(
      redirectToPaymentMethodsAction,
      behavior.redirectToPaymentMethodsPage,
    );
    yield takeEvery(LOCATION_CHANGE, behavior.handleLocationChange);
  };

export default makeMainSaga({
  behavior: {
    cancelExperience: paymentFlowService.cancelExperience,
    handleLocationChange,
    redirect: paymentFlowService.redirect,
    redirectToPaymentMethodsPage: paymentFlowService.redirectToPaymentSelection,
  },
});

export const behaviorForTesting = {
  makeMainSaga,
  doActionParamInstruction,
  actionParamInstructionsMap,
};
