import { put, select, takeLatest, call } from 'redux-saga/effects';

import * as concreteApi from '../../api';
import { frontEndAttemptID as frontEndAttemptIdSelector } from '../../selectors/analytics';
import { paymentId as paymentIdSelector } from '../../selectors/global';
import { bankById, searchFilter } from '../../selectors/instantBankTransfer';
import { paymentFlowFinishedAction } from '../actions/global';
import {
  ibtGetBanksAction,
  ibtStart,
  ibtSuccess,
  ibtError,
  ibtSelectBankAction,
  ibtSelectBankSuccessAction,
  ibtSelectBankErrorAction,
  ibtOfflineBankSelectAttemptAction,
  ibtStartV2,
} from '../actions/instantBankTransfer';
import { psSetMethodAction } from '../actions/paymentStatus';
import * as instantBankTransferAnalyticsService from './analytics/instantBankTransferAnalyticsService';
import * as paymentFlowService from './paymentFlowService';
import { redirect } from './paymentFlowService';

function* getBankList({ api }) {
  yield put(ibtStart());

  const paymentId = yield select(paymentIdSelector);

  try {
    const { json } = yield api.instantBankTransfer.getBankList({ paymentId });
    yield put(ibtSuccess(json));
  } catch (err) {
    yield put(ibtError(err));
  }
}

function* selectBank(
  { analytics, api, paymentFlow },
  { payload: issuerId }: any = {},
) {
  const paymentId = yield select(paymentIdSelector);
  const bank = yield select(bankById(issuerId));
  const search = yield select(searchFilter);

  try {
    if (!bank.online) {
      yield analytics.trackOfflineBankSelected(bank);
      yield put(ibtOfflineBankSelectAttemptAction(issuerId));
      return;
    }

    const frontendAttemptId = yield select(frontEndAttemptIdSelector);
    yield analytics.trackOnlineBankSelected(bank, search);
    const result = yield api.instantBankTransfer.getTransferRedirectDetails({
      issuerId,
      paymentId,
      frontendAttemptId,
    });
    const { json: redirectDetails } = result;

    yield put(
      ibtSelectBankSuccessAction({
        body: {
          encryptedAttemptId: redirectDetails.encryptedAttemptId,
        },
      }),
    );

    const flowFinishedAction = paymentFlowFinishedAction({
      type: 'INSTANT_BANK_TRANSFER',
      ...redirectDetails,
    });

    yield put(flowFinishedAction);
    yield paymentFlow.redirectViaFormSubmit(flowFinishedAction);
  } catch (error) {
    // TODO new relic
    yield put(ibtSelectBankErrorAction({ issuerId, error }));
  }
}

export function* redirectFormAction(payload) {
  const flowFinishedAction = yield call(paymentFlowFinishedAction, {
    requestUrl: payload.action.uri,
    request: payload.action.params,
  });

  yield put(flowFinishedAction);
  yield call(paymentFlowService.redirectViaFormSubmit, flowFinishedAction);
}

export function* initializeAction(payload) {
  const getCall = yield call(concreteApi.shared.get, payload.action.uri);
  const response = yield call(getCall, payload.action.params, payload);

  return response.json;
}

export function* selectBankV2({ payload }) {
  if (payload.methodType !== 'INSTANT_BANK_TRANSFER-V2') {
    return;
  }
  let response;
  const frontendAttemptId = yield select(frontEndAttemptIdSelector);

  while (true) {
    switch (payload.action.type) {
      case 'REDIRECT_FORM': {
        yield call(redirectFormAction, payload);
        break;
      }

      case 'INITIALIZATION': {
        // This is hardcoded for now, but in the generic solution, the backend should return the
        // `uri` field like this: "http://api-domain.com/some/endpoint?frontendAttemptId=:frontendAttemptId"
        // This way we can do a solution in the frontend that automatically fills this value.
        yield put(ibtStartV2());
        payload.action.uri =
          payload.action.uri + `?frontendAttemptId=${frontendAttemptId}`;
        response = yield call(initializeAction, payload);
        break;
      }

      case 'REDIRECT': {
        if (payload.action.uri) {
          yield call(redirect, { payload: { url: payload.action.uri } });
        }
        break;
      }

      default: {
        break;
      }
    }

    // The types here are final states, so the `while` can stop running
    if (payload.action.type === 'REDIRECT_FORM') {
      break;
    }
    if (payload.action.type === 'REDIRECT') {
      break;
    }

    payload = response;
  }
}

const makeMainSaga = ({ behavior, ...params }) =>
  function* instantBankTransferSaga() {
    yield takeLatest(ibtGetBanksAction, behavior.getBankList, params);
    yield takeLatest(ibtSelectBankAction, behavior.selectBank, params);
    yield takeLatest(psSetMethodAction as any, selectBankV2);
  };

export default makeMainSaga({
  analytics: instantBankTransferAnalyticsService,
  api: concreteApi,
  behavior: {
    getBankList,
    selectBank,
  },
  paymentFlow: paymentFlowService,
});

export const behaviorForTesting = {
  getBankList,
  selectBank,
  makeMainSaga,
};
