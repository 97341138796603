import { isArray } from 'lodash';
import { matchPath } from 'react-router-dom';
import { createSelector, createStructuredSelector } from 'reselect';

import { TAvailableIntegratorsConfig } from '../constants/integrators';
import {
  CARD,
  OVER_THE_COUNTER_RESULT,
  PAYMENT_RESULT,
  PAYMENT_SELECTION,
  BANK_TRANSFER_RESULT,
  PAYMENT_PENDING,
} from '../routes/pathConstants';
import { flavors, methods } from './paymentMethods';
import { paymentPendingVisible } from './paymentStatus';
import { isInErrorPath, isInInterstitialPath } from './routing';

const pathname = () => window.location.pathname;

export const showHeaderControls = createSelector(
  pathname,
  paymentPendingVisible,
  (path, isInterstitial) => !isPage(path, PAYMENT_RESULT) || isInterstitial,
);

export const showHeader = createSelector(
  pathname,
  isInErrorPath,
  (path, isError) =>
    !isPage(path, PAYMENT_RESULT) &&
    !isError &&
    !isPage(path, PAYMENT_PENDING) &&
    !isPage(path, BANK_TRANSFER_RESULT) &&
    !isPage(path, OVER_THE_COUNTER_RESULT),
);

export const showBackButton = createSelector(
  isInErrorPath,
  isInInterstitialPath,
  (isError, isInterstitial) => !(isError || isInterstitial),
);

export const shouldUseCancelUrl = createSelector(
  pathname,
  flavors,
  (path, flavors) =>
    isPage(path, PAYMENT_SELECTION) ||
    (isPage(path, CARD) && hasPSFlavor(flavors)),
);

export const shouldRedirectToPaymentMethodsPage = createSelector(
  pathname,
  flavors,
  (path, flavors) =>
    isPage(path, OVER_THE_COUNTER_RESULT) ||
    (isPage(path, CARD) && !hasPSFlavor(flavors)),
);

const paymentSecuredIcons = createSelector(methods, (methods) => {
  if (!isArray(methods)) {
    return [];
  }

  const paymentProvidersPerIntegrator: TAvailableIntegratorsConfig[] = [];

  const pspCheck: string[] = [];

  for (const { psp, pspIconUri } of methods) {
    if (pspIconUri && !pspCheck.includes(psp)) {
      pspCheck.push(psp);
      paymentProvidersPerIntegrator.push({
        psp,
        pspIconUri,
      });
    }
  }

  return paymentProvidersPerIntegrator;
});

export default createStructuredSelector<any, any>({
  showHeader,
  showHeaderControls,
  showBackButton,
  shouldUseCancelUrl,
  shouldRedirectToPaymentMethodsPage,
  paymentSecuredIcons,
});

function hasPSFlavor(flavors: string[] = []) {
  return flavors.includes('PRE_SELECTED_METHOD_CARD');
}

function isPage(path, expected) {
  return Boolean(matchPath(path, { path: expected, exact: true }));
}
