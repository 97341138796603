import { PlutusMessage } from '../types/PlutusMessages';

export const codesToTranslationKey: Partial<Record<PlutusMessage, string>> = {
  SESSION_EXPIRED: 'app.common.error.session_expired',
  RETRY_METHOD: 'app.common.error.retry_method',
  RE_ENTER_CARD_DATA: 'app.common.error.re_enter_card_data',
  REJECTED_BY_ISSUER: 'app.common.error.payment_rejected_issuer',
  GENERIC_ERROR_MESSAGE: 'app.common.error.generic',
  TRY_OTHER_METHOD: 'app.common.error.choose_another_method',
  PENDING: 'app.common.error.pending',
};

export const codesToTranslation = (code?: string) =>
  codesToTranslationKey[code || ''] || 'app.common.error.generic';
