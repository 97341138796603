import React, { FC, createContext, useContext, useState } from 'react';

import { AxiosError, AxiosResponse } from 'axios';

import { codesToTranslation } from '../../helpers';
import { TExecuteCommonApiResponse } from '../../hooks/usePaymentExecuteRequest/types';

type TErrorHandlerContextReturn = {
  handleApiErrorResponse: (response: any) => void;
  messageKey: string;
};

const ErrorHandlerContext = createContext<TErrorHandlerContextReturn>(
  {} as TErrorHandlerContextReturn,
);

export const useErrorHandler = () => useContext(ErrorHandlerContext);

export const ErrorHandlerProvider: FC = ({ children }) => {
  const [messageKey, setMessageKey] = useState('');

  const handleApiErrorResponse = (
    response: AxiosError<TExecuteCommonApiResponse> & AxiosResponse<TExecuteCommonApiResponse>,
  ) => {
    setMessageKey(codesToTranslation(response.response?.data?.message || response?.data?.message));
  };

  return (
    <ErrorHandlerContext.Provider
      value={{ messageKey, handleApiErrorResponse }}
    >
      {children}
    </ErrorHandlerContext.Provider>
  );
};
