import styled from '@emotion/styled';

import { theme } from '@olxui/core/dist/theme/CoreTheme/coreTheme';

export type ContainerProps = {
  type?: 'narrow' | 'wide' | '';
};

const containerStyleTypes = {
  narrow: `
    max-width: 448px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  `,
  wide: `
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    
    @media ${theme.mediaQueries.smMin} {
      width: ${getBreakpoint(theme.breakpoints.sm)};
    }

    @media ${theme.mediaQueries.mdMin} {
      width: ${getBreakpoint(theme.breakpoints.md)};
    }

    @media ${theme.mediaQueries.lgMin} {
      width: ${getBreakpoint(theme.breakpoints.lg)};
    }

    @media ${theme.mediaQueries.xlMin} {
      width: ${getBreakpoint(theme.breakpoints.xl)};
    }
  `,
};

const Container = styled('div')`
  ${({ type = '' }: ContainerProps) =>
    containerStyleTypes[type] || containerStyleTypes.narrow};
`;

export default Container;

function getBreakpoint(breakpoint) {
  return `${parseInt(breakpoint, 10) - 32}px`;
}
