import { lazy } from 'react';

import getLoadableComponent from '../helpers/getLoadableComponent';

const ErrorPage = getLoadableComponent(() => import('./ErrorPage/ErrorPage'));
const AboutPage = getLoadableComponent(() => import('./AboutPage'));
const CreditCardContainer = getLoadableComponent(
  () => import('./CreditCardPage/CreditCardContainer'),
);
const UpdatePageContainer = getLoadableComponent(
  () => import('./UpdatePage/UpdatePageContainer'),
);
const InstantBankTransferContainer = getLoadableComponent(
  () => import('./InstantBankTransferPage/InstantBankTransferContainer'),
);
const PaymentStatusContainer = getLoadableComponent(
  () => import('./PaymentStatusPage/PaymentStatusContainer'),
);
const PaymentSelectionPage = getLoadableComponent(
  () => import('./PaymentSelectionPage'),
);
const SmsPage = getLoadableComponent(() => import('./SmsPage/SmsPage'));
const PaymentPendingPage = getLoadableComponent(
  () => import('./PaymentPendingPage/PaymentPendingContainer'),
);
const MaintenancePage = getLoadableComponent(
  () => import('./MaintenancePage/MaintenancePage'),
);
const GenericPage = getLoadableComponent(() => import('./GenericPage'));

const PaymentResultMultibanco = lazy(() => import('./PaymentResultMultibanco'));

const PaymentResultMbway = lazy(() => import('./PaymentResultMbway'));

const MbWayPage = lazy(() => import('./MbWayPage'));
const CardPage = lazy(() => import('./CardPage'));

export {
  AboutPage,
  CreditCardContainer,
  InstantBankTransferContainer,
  PaymentPendingPage,
  PaymentStatusContainer,
  PaymentSelectionPage,
  SmsPage,
  MaintenancePage,
  ErrorPage,
  GenericPage,
  UpdatePageContainer,
  PaymentResultMultibanco,
  PaymentResultMbway,
  MbWayPage,
  CardPage,
};
