import { AxiosResponse } from 'axios';

import { PaymentMethodStatus } from '../constants/paymentStatus';
import { TExecuteCommonApiResponse } from '../hooks/usePaymentExecuteRequest/types';

export const responseHandlerInterceptor = (
  response: AxiosResponse<TExecuteCommonApiResponse>,
) => {
  if (
    [PaymentMethodStatus.ERROR, PaymentMethodStatus.REJECTED].includes(
      response?.data?.status as unknown as PaymentMethodStatus,
    ) ||
    response?.status !== 200
  ) {
    return Promise.reject(response);
  }

  return Promise.resolve(response);
};
