import React from 'react';

import styled from '@emotion/styled-base';
import { useIntl } from 'react-intl';

import { P3 } from '@olxui/core/dist/core/Typography';
import { theme } from '@olxui/core/dist/theme/CoreTheme/coreTheme';

import { TAvailableIntegratorsConfig } from '../../constants/integrators';
import Icon from '../Icon/Icon';

export const PaymentSecured = ({
  icons,
}: {
  icons: TAvailableIntegratorsConfig[];
}) => {
  const { formatMessage } = useIntl();

  return (
    <StyledContainer>
      <StyledP3>
        {formatMessage({
          id: 'app.payment_selection_page.payments_secured_by',
        })}
      </StyledP3>

      <StyledPspIconsContainer>
        {icons.map(({ psp, pspIconUri }) => (
          <PspIconStyled key={psp} className={psp}>
            <Icon id={pspIconUri} src={pspIconUri} alt={psp} />
          </PspIconStyled>
        ))}
      </StyledPspIconsContainer>
    </StyledContainer>
  );
};

const PspIconStyled = styled('div')`
  display: flex;
  align-items: center;

  min-width: 64px;
  min-height: auto;
  box-sizing: content-box;
  color: ${({ theme }: any) => theme.colors['background-brand-primary']};
  width: 64px;
  height: 25px;

  @media ${theme.mediaQueries.smMin} {
    width: 84px;
    height: 30px;
  }
`;

const StyledContainer = styled('div')`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;

  > div {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  @media ${theme.mediaQueries.smMin} {
    width: auto;
    align-items: end;
  }
`;

const StyledP3 = styled(P3)`
  font-weight: 500;
  font-size: 0.7rem;

  @media ${theme.mediaQueries.mdMin} {
    text-align: right;
  }
`;

const StyledPspIconsContainer = styled('div')`
  .PAYU:last-child {
    margin-right: -10px;
  }
`;
