import * as nr from '../../helpers/new-relic-wrapper';
import type { ApiV2Response } from '../../types/ApiV2Response';
import type { InterceptorType } from './InterceptorType';

export const logRequestStatus: InterceptorType = {
  predicate: () => true,
  sideEffect: (payload: ApiV2Response, res, url, method, options) => {
    setTimeout(async () => {
      let text;
      let json;
      let jsonError;

      try {
        text = await res?.clone().text();
      } catch (e) {
        text = null;
      }
      try {
        json = await res?.clone().json();
      } catch (e: any) {
        json = null;
        jsonError = e.message;
      }

      delete options.headers?.Authorization;

      if (res && res.status < 400) {
        return nr.addPageAction(nr.PAGE_ACTION_NAMES.API, {
          status: nr.API_STATUS.SUCCESS,
          requestUrl: url,
          options: { ...options },
          resStatus: res.status,
          jsonError,
        });
      }
      nr.addPageAction(nr.PAGE_ACTION_NAMES.API, {
        status: nr.API_STATUS.FAIL,
        requestUrl: url,
        method,
        options: { ...options },
        text,
        json,
        jsonError,
        resStatus: res?.status,
      });
    }, 0);
    return payload;
  },
};
