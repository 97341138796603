import React from 'react';

import styled from '@emotion/styled';

import { Modal as ModalCore } from '@olxui/core-v10/dist/core/Modal/Modal';

export default ({ isOpen, ...rest }: any) =>
  isOpen ? <Modal {...rest} /> : null;

const Modal = styled(ModalCore)`
  left: 0;
  top: 0;
  position: relative;
  margin: 16px auto;
  max-width: 408px !important;
`;
