import { AdyenPaymentProvider } from './adyen';
import { PayuPaymentProvider } from './payu';
import { PortmonePaymentProvider } from './portmone';

const providers = {
  ADYEN: () => import('./adyen'),
  // TODO: Re-introduce Portmone after we pass PCI.
  PORTMONE: () => import('./portmone'),
  PAYU: () => import('./payu'),
};

const loadedProviders = {};

export async function getPaymentProviderInstance(
  name: string,
): Promise<
  AdyenPaymentProvider | PortmonePaymentProvider | PayuPaymentProvider
> {
  if (typeof name !== 'string') {
    throw new Error(
      `getPaymentProviderInstance must receive an argument "name" of type string. It got ${name}`,
    );
  }

  const ucName = name.toUpperCase();

  if (!loadedProviders[ucName]) {
    const loadProvider = providers[ucName];

    if (typeof loadProvider !== 'function') {
      throw new Error(`No provider for name ${ucName}`);
    }

    const provider = await loadProvider();
    loadedProviders[ucName] = provider.default;
  }

  return loadedProviders[ucName];
}
