import type { Tracker } from '../../../types/Tracker';
import compositeTracker from './compositeTracker';

const trackCCRedirectActionWith = (tracker: Tracker) =>
  function* doTrackCCRedirectAction() {
    yield tracker.trackEvent({
      eventName: 'Redirection_3DS',
      params: {
        // This is a hack. Currently we have 3ds flow for cards only.
        methodType: 'new_card',
      },
    });
  };

export const trackCCRedirectAction =
  trackCCRedirectActionWith(compositeTracker);

export const behaviorForTesting = {
  // TODO test this.
  trackCCRedirectActionWith,
};
